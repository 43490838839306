import { Injectable, NgZone } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, BehaviorSubject, of, from, defer, pipe } from "rxjs";
import { switchMap } from "rxjs/internal/operators/switchMap";
import * as firebase from "firebase";
import { map, tap, first } from "rxjs/operators";
import { User } from "../../models/user";
import { Product } from "../../models/product";
import { convertSnaps } from "./db-util";
import { Message } from "src/app/models/message";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user$: Observable<User>;
  isEmailVerified$: Observable<boolean>;
  private eventAuthError = new BehaviorSubject<string>("");
  eventAuthError$ = this.eventAuthError.asObservable();
  businessOwners$: Observable<User[]>;
  businessOwnerProducts$: Observable<Product[]>;

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    public ngZone: NgZone
  ) {
    // Get auth data, then get firestore user document || null
    this.user$ = afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.db.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  registerEmailUser(fname: string, email: string, password: string): Observable<any> {
    return from(
      this.afAuth
        .createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          this.SendVerificationMail(email);
          this.registerEmailUserInfo(userCredential.user, fname, email);
        })
        .catch((error) => {
          this.eventAuthError.next(error);
        })
    );
  }

  private registerEmailUserInfo(user: firebase.default.User, fname: string, email: string) {
    const userRef: AngularFirestoreDocument<any> = this.db.doc(
      `users/${user.uid}`
    );
    // Save belwo data in the localstorage
    /*
    sessionStorage.setItem("userId", user.uid);
    this.user$.subscribe(user => {
      sessionStorage.setItem('avatarUrlLink', user.avatarUrlLink);
      sessionStorage.setItem('fname', user.fname)
    });
    */

    const data: User = {
      uid: user.uid,
      fname: fname,
      email: email,
      emailVerified: false,
      isBusinessOwner: false,
      avatarUrlLink: this.getAvatarUrlLink(fname),
      isReadyToSendWelcomeEmail: false,
      showShoppingCart: false,
      isThirdPartySignIn: false,
      productPublisedCounter: 0,

      //Sell - Clothings
      isMaxFashionClothingsReached: false,
      maxFashionClothings: 4,
      counterFashionClothings: 0,

      isMaxFashionJewelleryReached: false,
      maxFashionJewellery: 2,
      counterFashionJewellery: 0,

      isMaxFashionShoesReached: false,
      maxFashionShoes: 4,
      counterFashionShoes: 0,

      isMaxFashionWatchesReached: false,
      maxFashionWatches: 2,
      counterFashionWatches: 0,

      isMaxFashionHandbagsAndAccessoriesReached: false,
      maxFashionHandbagsAndAccessories: 2,
      counterFashionHandbagsAndAccessories: 0,

      isMaxElectronicsComputersTabletsReached: false,
      maxElectronicsComputersTablets: 4,
      counterElectronicsComputersTablets: 0,

      isMaxElectronicsSmartphonesReached: false,
      maxElectronicsSmartphones: 4,
      counterElectronicsSmartphones: 0,

      isMaxElectronicsTVHomeReached: false,
      maxElectronicsTVHome: 4,
      counterElectronicsTVHome: 0,

      isMaxElectronicsRefurbishedReached: false,
      maxElectronicsRefurbished: 4,
      counterElectronicsRefurbished: 0,

      isMaxFurnituresLivingRoomReached: false,
      maxFurnituresLivingRoom: 4,
      counterFurnituresLivingRoom: 0,

      isMaxFurnituresBedroomReached: false,
      maxFurnituresBedroom: 4,
      counterFurnituresBedroom: 0,

      isMaxFurnituresDiningRoomReached: false,
      maxFurnituresDiningRoom: 4,
      counterFurnituresDiningRoom: 0,

      isMaxFurnitureskidsRoomReached: false,
      maxFurnituresKidsRoom: 4,
      counterFurnituresKidsRoom: 0,

      isMaxAppliancesKitchenReached: false,
      maxAppliancesKitchen: 4,
      counterAppliancesKitchen: 0,

      isMaxAppliancesLaundryReached: false,
      maxAppliancesLaundry: 4,
      counterAppliancesLaundry: 0,

      isMaxHealthBeautyReached: false,
      maxHealthBeauty: 4,
      counterHealthBeauty: 0,

      //Cars & Vehicules - Cars & Trucks
      isMaxAllowedCarsAndVehiculesCarsAndTrucksProductsReached: false,
      maxAllowedCarsAndVehiculesCarsAndTrucksProducts: 2,
      productCounterCarsAndVehiculesCarsAndTrucks: 0,

       //Cars & Vehicules - Vehicule Parts Tires and Accessories
       isMaxAllowedCarsAndVehiculesVehiculePartsTiresAccessoriesProductsReached: false,
       maxAllowedCarsAndVehiculesVehiculePartsTiresAccessoriesProducts: 2,
       productCounterCarsAndVehiculesVehiculePartsTiresAccessories: 0,

      // Real Estate - For Rent
      isMaxAllowedRealEstateForRentProductsReached: false,
      maxAllowedRealEstateForRentProducts: 2,
      productCounterRealEstateForRent: 0,

       // Real Estate - For Rent
       isMaxAllowedRealEstateForSaleProductsReached: false,
       maxAllowedRealEstateForSaleProducts: 2,
       productCounterRealEstateForSale: 0,

      urlTransition: fname + "?" + email.substring(2, email.indexOf('@')),
      createdUserDate: this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
      expireUserDate: '9999-12-31',
      roles: {
        individual: true,
      },
    };
    return userRef.set(data, { merge: true });
  }

  updateEmailVerified(): Observable<boolean> { 
    return from(this.afAuth.currentUser
    .then((userCredential) => {
      this.updateEmailVerifiedInfo(userCredential);
      return true;
    }));
  }

  private updateEmailVerifiedInfo(user: firebase.default.User) {
    const userRef: AngularFirestoreDocument<any> = this.db.doc(
      `users/${user.uid}`
    );
    const data: User = {
      emailVerified: true,
    };
    return userRef.set(data, { merge: true });
  }

  registerBusiness(userInfo: User) {
    return this.afAuth.currentUser
      .then((userCredential) => {
        this.registerBusinessInfo(userCredential, userInfo);
      })
      .catch((error) => {
        this.eventAuthError.next(error);
      });
  }

  updateAccountInformation(userInfo: User, isThirdPartySignIn: boolean, user: User) {
    return this.afAuth.currentUser
      .then((userCredential) => {
        this.updateBusinessInfo(userCredential, userInfo, isThirdPartySignIn, user);
      })
      .catch((error) => {
        this.eventAuthError.next(error);
      });
  }

  EmailAddressInformation(userInfo: User) {
    return this.afAuth.currentUser
      .then(async (userCredential) => {
        await userCredential.updateEmail(userInfo.email);
        await this.registerBusinessInfo(userCredential, userInfo);
      })
      .catch((error) => {
        this.eventAuthError.next(error);
      });
  }

  updatePassword(password: string) {
    return this.afAuth.currentUser
      .then(async (userCredential) => {
        await userCredential.updatePassword(password);
      })
      .catch((error) => {
        this.eventAuthError.next(error);
      });
  }

  updateTaxRateInformation(userInfo: User, currentUser: string) {
    const userRef: AngularFirestoreDocument<any> = this.db.doc(
      `users/${currentUser}`
    );
    return userRef.set(userInfo, { merge: true });
  }

  private registerBusinessInfo(user: firebase.default.User, userInfo) {;
    const userRef: AngularFirestoreDocument<any> = this.db.doc(
      `users/${user.uid}`
    );
    return userRef.set(userInfo, { merge: true });
  }

  private updateBusinessInfo(userCredential: firebase.default.User, userInfo: User, isThirdPartySignIn: boolean, user: User) {
    if (isThirdPartySignIn) {
      if (user.roles.productOrientedBusiness === true || user.roles.individual === true) {
        this.db.collection<User>('users')
        .doc(userCredential.uid)
        .update({
          businessName: userInfo.businessName,
          telephone: userInfo.telephone,
          serviceAboutUs: userInfo.serviceAboutUs,
          address: userInfo.address
        });
      } else {
        this.db.collection<User>('users')
        .doc(userCredential.uid)
        .update({
          businessName: userInfo.businessName,
          telephone: userInfo.telephone,
          serviceAboutUs: userInfo.serviceAboutUs,
          serviceDescription: userInfo.serviceDescription,
          address: userInfo.address
        });
      }
    } else {
      if (user.roles.productOrientedBusiness === true || user.roles.individual === true) {
        this.db.collection<User>('users')
        .doc(userCredential.uid)
        .update({
          fname: userInfo.fname,
          businessName: userInfo.businessName,
          telephone: userInfo.telephone,
          serviceAboutUs: userInfo.serviceAboutUs,
          address: userInfo.address
        });
      } else {
        this.db.collection<User>('users')
        .doc(userCredential.uid)
        .update({
          fname: userInfo.fname,
          businessName: userInfo.businessName,
          telephone: userInfo.telephone,
          serviceAboutUs: userInfo.serviceAboutUs,
          serviceDescription: userInfo.serviceDescription,
          address: userInfo.address
        });
      }
    }
    
  }

  login(
    email: string,
    password: string
  ): Observable<firebase.default.auth.UserCredential> {
    return from(this.afAuth.signInWithEmailAndPassword(email, password));
  }

  ///// Role-based Authorization //////

  canRead(user: User): boolean {
    const allowed = ["individual", "productOrientedBusiness", "serviceOrientedBusiness", "admin"];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: User): boolean {
    const allowed = ["productOrientedBusiness", "serviceOrientedBusiness", "admin"];
    return this.checkAuthorization(user, allowed);
  }

  canAdmin(user: User): boolean {
    const allowed = ["admin"];
    return this.checkAuthorization(user, allowed);
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }

  logout() {
    return this.afAuth.signOut().then(() => {
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("returnUrl");
      sessionStorage.removeItem("boId");
      sessionStorage.removeItem("businessName");
      sessionStorage.removeItem("messageId");
      sessionStorage.removeItem("fname");
      sessionStorage.removeItem("avatarUrlLink");
      sessionStorage.removeItem('isMessageSend');
      sessionStorage.removeItem('productOwnerId');
      sessionStorage.removeItem('currency');
      this.router.navigate(["/"]);
    })
    .catch(error => {
      console.log('error occurred' + error)
    });
  }

  plogout() {
    return from(
      this.afAuth.signOut().then(() => {
        sessionStorage.removeItem("userId");
        window.location.reload();
      })
    );
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail(email: string) {
    const actionCodeSettings = {
      url: `http://kulan.ca/auth?cartId=1234`,
      //url: `http://localhost:4200/auth?cartId=1234`,
      handleCodeInApp: true,
    };
    return this.afAuth
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        console.log('');
      })
      .catch(function (error) {
        // Some error occurred, you can inspect the code: error.code
        console.log(`Error has occurred ${error}`);
      });
  }

  // Reset Forgot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        console.log("Password reset email sent, check your inbox.");
      })
      .catch((error) => {
        this.eventAuthError.next(error);
      });
  }

  addContactInformation(message: Message): Observable<any> {
    return from(this.db.collection("contacts").add(message));
  }

  addNewsletter(newsletterInfo: { email: any }) {
    return from(this.db.collection("newsletters").add(newsletterInfo));
  }

  
  validateEmailUserInfo(email: string): Observable<User> {
    return this.db
      .collection<User>("users", (ref) => ref.where("email", "==", email))
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          const users = convertSnaps<User>(snaps);
          return users.length == 1 ? users[0] : undefined;
        }),
        first()
      );
  }

  loadUsers(): Observable<User[]> {
    return this.db.collection<User>('users').snapshotChanges()
        .pipe(
            map(snaps => {
                const users = convertSnaps<User>(snaps)
                return users;
            })
        );
  }

  getCityName(userId: string) {
    
  }

  googleLogin() {
    // get return url from route parameters or default to '/'
    let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';

    localStorage.setItem("returnUrl", returnUrl);;
    
    return this.oAuthGoogleLogin(new firebase.default.auth.GoogleAuthProvider());
    
  }

  facebookLogin() {
    // get return url from route parameters or default to '/'
    let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';

    localStorage.setItem("returnUrl", returnUrl);
    
    return this.oAuthFacebookLogin(new firebase.default.auth.FacebookAuthProvider());
    
  }

  oAuthGoogleLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
        .then(credential => {
            this.ngZone.run(() => {
                let returnUrl = localStorage.getItem('returnUrl');
                if (returnUrl !== '/') {
                    this.router.navigate([returnUrl]);
                }
                else {
                    this.router.navigate(['account']);
                }
            })
            this.updateGoogleUserData(credential);
            sessionStorage.setItem('userId', credential.user.uid);
        })
        .catch(error => {
            this.eventAuthError.next(error);
        })
  }

  oAuthFacebookLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
        .then(credential => {
            this.ngZone.run(() => {
                let returnUrl = localStorage.getItem('returnUrl');
                if (returnUrl !== '/') {
                    this.router.navigate([returnUrl]);
                }
                else {
                    this.router.navigate(['account']);
                }
            })
            this.updateFacebookUserData(credential);
            sessionStorage.setItem('userId', credential.user.uid);
        })
        .catch(error => {
            this.eventAuthError.next(error);
        })
  }

  private updateGoogleUserData(credential: firebase.default.auth.UserCredential) {
    // Sets user data to firestore on login
    localStorage.setItem('userId', credential.user.uid);
    const userRef: AngularFirestoreDocument<any> = this.db.doc(`users/${credential.user.uid}`);

    if (credential.additionalUserInfo.isNewUser) {
      const data: User = {
        uid: credential.user.uid,
        email: credential.user.email,
        fname: credential.user.displayName,
        emailVerified: credential.user.emailVerified,
        avatarUrlLink: this.getAvatarUrlLink(credential.user.displayName),
        urlTransition: credential.user.displayName + "?" + credential.user.email.substring(2, credential.user.email.indexOf('@')),
        isReadyToSendWelcomeEmail: false,
        showShoppingCart: false,
        isThirdPartySignIn: true,
        productPublisedCounter: 0,
        isBusinessOwner: false,
        
        //Sell - Clothings
        isMaxFashionClothingsReached: false,
        maxFashionClothings: 4,
        counterFashionClothings: 0,

        isMaxFashionJewelleryReached: false,
        maxFashionJewellery: 2,
        counterFashionJewellery: 0,

        isMaxFashionShoesReached: false,
        maxFashionShoes: 4,
        counterFashionShoes: 0,

        isMaxFashionWatchesReached: false,
        maxFashionWatches: 2,
        counterFashionWatches: 0,

        isMaxFashionHandbagsAndAccessoriesReached: false,
        maxFashionHandbagsAndAccessories: 2,
        counterFashionHandbagsAndAccessories: 0,

        isMaxElectronicsComputersTabletsReached: false,
        maxElectronicsComputersTablets: 4,
        counterElectronicsComputersTablets: 0,

        isMaxElectronicsSmartphonesReached: false,
        maxElectronicsSmartphones: 4,
        counterElectronicsSmartphones: 0,

        isMaxElectronicsTVHomeReached: false,
        maxElectronicsTVHome: 4,
        counterElectronicsTVHome: 0,

        isMaxElectronicsRefurbishedReached: false,
        maxElectronicsRefurbished: 4,
        counterElectronicsRefurbished: 0,

        isMaxFurnituresLivingRoomReached: false,
        maxFurnituresLivingRoom: 4,
        counterFurnituresLivingRoom: 0,

        isMaxFurnituresBedroomReached: false,
        maxFurnituresBedroom: 4,
        counterFurnituresBedroom: 0,

        isMaxFurnituresDiningRoomReached: false,
        maxFurnituresDiningRoom: 4,
        counterFurnituresDiningRoom: 0,

        isMaxFurnitureskidsRoomReached: false,
        maxFurnituresKidsRoom: 4,
        counterFurnituresKidsRoom: 0,

        isMaxAppliancesKitchenReached: false,
        maxAppliancesKitchen: 4,
        counterAppliancesKitchen: 0,

        isMaxAppliancesLaundryReached: false,
        maxAppliancesLaundry: 4,
        counterAppliancesLaundry: 0,

        isMaxHealthBeautyReached: false,
        maxHealthBeauty: 4,
        counterHealthBeauty: 0,

        //Cars & Vehicules - Cars & Trucks
        isMaxAllowedCarsAndVehiculesCarsAndTrucksProductsReached: false,
        maxAllowedCarsAndVehiculesCarsAndTrucksProducts: 2,
        productCounterCarsAndVehiculesCarsAndTrucks: 0,

        //Cars & Vehicules - Vehicule Parts Tires and Accessories
        isMaxAllowedCarsAndVehiculesVehiculePartsTiresAccessoriesProductsReached: false,
        maxAllowedCarsAndVehiculesVehiculePartsTiresAccessoriesProducts: 2,
        productCounterCarsAndVehiculesVehiculePartsTiresAccessories: 0,

        // Real Estate - For Rent
        isMaxAllowedRealEstateForRentProductsReached: false,
        maxAllowedRealEstateForRentProducts: 2,
        productCounterRealEstateForRent: 0,

        // Real Estate - For Rent
        isMaxAllowedRealEstateForSaleProductsReached: false,
        maxAllowedRealEstateForSaleProducts: 2,
        productCounterRealEstateForSale: 0,

        isMaxServicesReached: false,
        maxServices: 4,
        counterServices: 0,

        createdUserDate: this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
        expireUserDate: '9999-12-31',

        roles: {
            individual: true
        }
      }
      return userRef.set(data, { merge: true })
    }  else {
      console.log('');
    }
}


private updateFacebookUserData(credential: firebase.default.auth.UserCredential) {
  // Sets user data to firestore on login
  localStorage.setItem('userId', credential.user.uid);
  const userRef: AngularFirestoreDocument<any> = this.db.doc(`users/${credential.user.uid}`);

  if (credential.additionalUserInfo.isNewUser) {
    const data: User = {
      uid: credential.user.uid,
      email: credential.user.email,
      fname: credential.user.displayName,
      emailVerified: true,
      avatarUrlLink: this.getAvatarUrlLink(credential.user.displayName),
      urlTransition: credential.user.displayName + "?" + credential.user.email.substring(2, credential.user.email.indexOf('@')),
      isReadyToSendWelcomeEmail: false,
      showShoppingCart: false,
      isThirdPartySignIn: true,
      productPublisedCounter: 0,
      isBusinessOwner: false,
      
      //Sell - Clothings
      isMaxFashionClothingsReached: false,
      maxFashionClothings: 4,
      counterFashionClothings: 0,

      isMaxFashionJewelleryReached: false,
      maxFashionJewellery: 2,
      counterFashionJewellery: 0,

      isMaxFashionShoesReached: false,
      maxFashionShoes: 4,
      counterFashionShoes: 0,

      isMaxFashionWatchesReached: false,
      maxFashionWatches: 2,
      counterFashionWatches: 0,

      isMaxFashionHandbagsAndAccessoriesReached: false,
      maxFashionHandbagsAndAccessories: 2,
      counterFashionHandbagsAndAccessories: 0,

      isMaxElectronicsComputersTabletsReached: false,
      maxElectronicsComputersTablets: 4,
      counterElectronicsComputersTablets: 0,

      isMaxElectronicsSmartphonesReached: false,
      maxElectronicsSmartphones: 4,
      counterElectronicsSmartphones: 0,

      isMaxElectronicsTVHomeReached: false,
      maxElectronicsTVHome: 4,
      counterElectronicsTVHome: 0,

      isMaxElectronicsRefurbishedReached: false,
      maxElectronicsRefurbished: 4,
      counterElectronicsRefurbished: 0,

      isMaxFurnituresLivingRoomReached: false,
      maxFurnituresLivingRoom: 4,
      counterFurnituresLivingRoom: 0,

      isMaxFurnituresBedroomReached: false,
      maxFurnituresBedroom: 4,
      counterFurnituresBedroom: 0,

      isMaxFurnituresDiningRoomReached: false,
      maxFurnituresDiningRoom: 4,
      counterFurnituresDiningRoom: 0,

      isMaxFurnitureskidsRoomReached: false,
      maxFurnituresKidsRoom: 4,
      counterFurnituresKidsRoom: 0,

      isMaxAppliancesKitchenReached: false,
      maxAppliancesKitchen: 4,
      counterAppliancesKitchen: 0,

      isMaxAppliancesLaundryReached: false,
      maxAppliancesLaundry: 4,
      counterAppliancesLaundry: 0,

      isMaxHealthBeautyReached: false,
      maxHealthBeauty: 4,
      counterHealthBeauty: 0,

      //Cars & Vehicules - Cars & Trucks
      isMaxAllowedCarsAndVehiculesCarsAndTrucksProductsReached: false,
      maxAllowedCarsAndVehiculesCarsAndTrucksProducts: 2,
      productCounterCarsAndVehiculesCarsAndTrucks: 0,

      //Cars & Vehicules - Vehicule Parts Tires and Accessories
      isMaxAllowedCarsAndVehiculesVehiculePartsTiresAccessoriesProductsReached: false,
      maxAllowedCarsAndVehiculesVehiculePartsTiresAccessoriesProducts: 2,
      productCounterCarsAndVehiculesVehiculePartsTiresAccessories: 0,

      // Real Estate - For Rent
      isMaxAllowedRealEstateForRentProductsReached: false,
      maxAllowedRealEstateForRentProducts: 2,
      productCounterRealEstateForRent: 0,

      // Real Estate - For Rent
      isMaxAllowedRealEstateForSaleProductsReached: false,
      maxAllowedRealEstateForSaleProducts: 2,
      productCounterRealEstateForSale: 0,

      createdUserDate: this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
      expireUserDate: '9999-12-31',

      roles: {
          individual: true
      }
    }
    return userRef.set(data, { merge: true })
  } else {
    console.log('');
  }
 
}

getAvatarUrlLink(fname: string): string {
    const firstCharacter = fname.charAt(0);

    switch (firstCharacter) {
      case 'A':
        return '/assets/alphabet-images/a.png';
      case 'B':
        return '/assets/alphabet-images/b.png';
      case 'C':
        return '/assets/alphabet-images/c.png';
      case 'D':
        return '/assets/alphabet-images/d.png';
      case 'E':
        return '/assets/alphabet-images/e.png';
      case 'F':
        return '/assets/alphabet-images/f.png';
      case 'G':
        return '/assets/alphabet-images/g.png'
      case 'H':
        return '/assets/alphabet-images/h.png';
      case 'I':
        return '/assets/alphabet-images/i.png';
      case 'J':
        return '/assets/alphabet-images/j.png';
      case 'K':
        return '/assets/alphabet-images/k.png';
      case 'L':
        return '/assets/alphabet-images/l.png';
      case 'M':
        return '/assets/alphabet-images/m.png';
      case 'N':
        return '/assets/alphabet-images/n.png';
      case 'O':
        return '/assets/alphabet-images/o.png';
      case 'P':
        return '/assets/alphabet-images/p.png';
      case 'Q':
        return '/assets/alphabet-images/q.png';
      case 'R':
        return '/assets/alphabet-images/r.png';
      case 'S':
        return '/assets/alphabet-images/s.png';
      case 'T':
        return '/assets/alphabet-images/t.png';
      case 'U':
        return '/assets/alphabet-images/u.png';
      case 'V':
        return '/assets/alphabet-images/v.png';
      case 'W':
        return '/assets/alphabet-images/w.png';
      case 'X':
        return '/assets/alphabet-images/x.png';
      case 'Y':
        return '/assets/alphabet-images/y.png';
      case 'Z':
        return '/assets/alphabet-images/z.png';
      default:
        break;
    }

  }
}
