import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { Product } from 'src/app/models/product';
import { AuthService } from '../services/auth.service';
import { BusinessOwnersService } from '../services/business-owners.service';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: Product;
  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count:number = 1;
  public align = 'center center';
  public isBusinessOwner: boolean = false;
  constructor(public snackBar: MatSnackBar, public auth: AuthService, private businessOwnersService: BusinessOwnersService) { }

  ngOnInit() {
    if(this.product){

      const productOwnerId = sessionStorage.getItem('productOwnerId');
      
      if (productOwnerId) {
        this.businessOwnersService.getBusinessOwner(productOwnerId)
        .pipe(
          map(user => {
            if (user) {
              this.isBusinessOwner = user.isBusinessOwner;
            }
          })
        ).subscribe()
      } 
      
      if(this.product.quantity > 0){
        this.count = this.product.quantity;
      }
    }  
    this.layoutAlign(); 
  }

  public layoutAlign(){
    if(this.type == 'all'){
      this.align = 'space-between center';
    }
    else if(this.type == 'wish'){
      this.align = 'start center';
    }
    else{
      this.align = 'center center';
    }
  }



  public increment(count){
    if(this.count < this.product.quantity){
      this.count++;
      let obj = {
        productId: this.product.productId,
        soldQuantity: this.count,
        total: this.count * this.product.newPrice
      }
      this.changeQuantity(obj);
    }
    else{
      this.snackBar.open('You can not choose more items than available. In stock ' + this.count + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }    
  }

  
  public openProductDialog(event){
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value){
      this.onQuantityChange.emit(value);
  }

}