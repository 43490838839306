import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TopMenuComponent } from './theme/components/top-menu/top-menu.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';

import { AppSettings } from './app.settings';
import { AppInterceptor } from './theme/utils/app-interceptor';
import { OptionsComponent } from './theme/components/options/options.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { AuthService } from './shared/services/auth.service';

//* Firestore *//
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import {
  SessionInteruptService,
  SessionExpirationAlert,
} from 'session-expiration-alert';
import { AppSessionInteruptService } from './shared/services/app-session-interupt.service';
import { ProductService } from './shared/services/product.service';
import { FileService } from './shared/services/file.service';
import { ManageProductService } from './shared/services/manage-product.service';
import { CanEditGuard } from './shared/guard/can-edit-guard';
import { AdminGuard } from './shared/guard/admin-guard';
import { CanReadGuard } from './shared/guard/can-read-guard';
import { LogoService } from './shared/services/logo.service';
import { BannersService } from './shared/services/banners.service';
import { BusinessOwnersService } from './shared/services/business-owners.service';
import { DatePipe } from '@angular/common';
import { ShoppingCartService } from './shared/services/shopping-cart.service';
import { MessengerService } from './shared/services/messenger.service';
import { OrderService } from './shared/services/order.service';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { EventService } from './shared/services/event.service';
import { GenericService } from './shared/services/generic.service';
import { IndividualService } from './shared/services/individual.service';
import { BusinessService } from './shared/services/business.service';
import { ChatService } from './shared/services/chat.service';

import { SwiperModule, SwiperConfigInterface,
  SWIPER_CONFIG } from 'ngx-swiper-wrapper';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AppRoutingModule,
    TranslocoRootModule,
    NgxSpinnerModule,
    SwiperModule
    
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    TopMenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent
  ],
  providers: [
    AppSettings,
    AuthService,
    CanEditGuard,
    AdminGuard,
    CanReadGuard,
    ProductService,
    LogoService,
    BannersService,
    ShoppingCartService,
    DatePipe,
    MessengerService,
    OrderService,
    ManageProductService,
    FileService,
    BusinessOwnersService,
    EventService,
    GenericService,
    IndividualService,
    ChatService,
    BusinessService,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: menuScrollStrategy,
      deps: [Overlay],
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'accent' } },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
