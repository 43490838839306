<div class="close-btn-outer">
    <button mat-mini-fab color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
</div>
<div mat-dialog-content>
    <div fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-sm="50"> 
            <div class="swiper-container h-100" [swiper]="config">
                <div class="swiper-wrapper">      
                    <div *ngFor="let image of product.imageStorageUrls" class="swiper-slide">
                        <img [src]="image" *ngIf="image" class="swiper-lazy"/>
                        <div class="swiper-lazy-preloader"></div>
                    </div>
                </div>
                <button mat-icon-button class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
                <button mat-icon-button class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
            </div>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" ngClass.gt-sm="px-2 m-0" ngClass.sm="mt-2" ngClass.xs="mt-2"> 
            <h2>{{product.name}}</h2>
            <div class="py-1 lh">
               
            </div>                 
            <div class="py-1">
                
            </div>
            <div class="full-desc lh">
                <div style="font-size: large;" [innerHTML]="product.description"></div>
              </div>
            <div fxLayoutAlign="end center" class="text-muted">                 
                 <button [mat-dialog-close]="product" mat-icon-button matTooltip="View full details"></button>         
            </div>              
            <div class="divider"></div>
            <div *ngIf="product.newPrice !== undefined">
                <h2 class="mt-2 new-price">{{product.currency + " " + product?.newPrice }}</h2>
            </div>
            
        </div>
    </div>
</div>