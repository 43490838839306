<div fxLayout="row" class="mt-4">
    <div fxFlex="70">
        <h2>Related Products</h2>
    </div> 
</div>
<mat-divider color="primary"></mat-divider>

<div *ngIf="products" class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper h-100">      
        <div *ngFor="let product of products" class="swiper-slide">
            <mat-card class="product-item">
                <mat-chip-list *ngIf="product.discount">
                    <mat-chip color="warn" selected="true">{{product.discount}}% OFF</mat-chip>
                </mat-chip-list>
                <a class="image-link">
                    <img [src]="product.imageStorageUrls[0]" *ngIf="product.imageStorageUrls[0]" class="swiper-lazy"/>
                </a>                                                             
                <a class="title text-truncate">
                    <h6>{{product.name}}</h6>
                </a>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="prices">
                    <div fxLayout="column" fxLayoutAlign="center start">
                        <p class="old-price text-muted"><span *ngIf="product.oldPrice">${{product.oldPrice | number : '1.2-2'}}</span></p>
                        <p class="new-price">${{product.newPrice | number : '1.2-2'}}</p>
                    </div>
                    
                </div>                            
                <div class="divider mt-2"></div>
                <div class="icons">
                    <app-controls [product]="product" (onOpenProductDialog)="openProductDialog(product)"></app-controls>
                </div>
            </mat-card>
        </div>
    </div>
    <button mat-mini-fab class="swiper-button-prev swipe-arrow mt-1"><mat-icon>keyboard_arrow_left</mat-icon></button>
    <button mat-mini-fab class="swiper-button-next swipe-arrow mt-1"><mat-icon>keyboard_arrow_right</mat-icon></button> 
</div>
