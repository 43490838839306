import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BusinessService } from '../services/business.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  @Input('banners') banners: Array<any> = [];

  constructor(private businessService: BusinessService, public router: Router) { }

  ngOnInit() { }

  public getBanner(index){
    return this.banners[index];
  }

  public getBgImage(index){
    let bgImage = {
      'background-image': index != null ? "url(" + this.banners[index].image + ")" : "url(https://via.placeholder.com/600x400/ff0000/fff/)"
    };
    return bgImage;
  } 

  public visitStore1() {
    const businessOwnerId = '63WALbkcgtWQDNJ3e78THAhGSkk1';
    
    this.businessService.getBusiness(businessOwnerId)
      .pipe(
        map(user => {
          if (user) {
            this.router.navigate(['/product-owner', user.urlTransition, 'products']);  
          }
        })
      ).subscribe()
  }

  public visitStore2() {
    const businessOwnerId = 'c3XGsJ3p7tbKexhqOY9BMgB2iSy2';
    
    this.businessService.getBusiness(businessOwnerId)
      .pipe(
        map(user => {
          if (user) {
            this.router.navigate(['/product-owner', user.urlTransition, 'products']);  
          }
        })
      ).subscribe()
  }

}
