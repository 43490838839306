import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { Product } from '../../models/product';
import { first, map } from 'rxjs/operators';
import { ShoppingCart } from '../../models/shopping-cart';
import { ShoppingCartItem } from '../../models/shopping-cart-item';
import { convertSnaps } from './db-util';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  constructor(private db: AngularFirestore) {}

  async addToCart(product: Product) {
    let cartId = await this.getOrCreateCartId();
    const docRef = this.getItem(cartId, product.productId);

    if ((await docRef.get()).exists) {
      docRef.update({
        quantity: (await docRef.get()).data().quantity + 1,
      });
    } else {
      docRef.set({
        productId: product.productId,
        businessOwnerId: product.productOwnerId,
        name: product.name,
        price: product.newPrice,
        imageUrl: product.imageStorageUrls[0],
        quantity: 1,
      });
    }
  }

  async removeFromCart(product: Product) {
    let cartId = await this.getOrCreateCartId();
    const docRef = this.getItem(cartId, product.productId);

    if ((await docRef.get()).exists) {
      docRef.update({
        quantity: (await docRef.get()).data().quantity - 1,
      });
    }
  }

  private async getOrCreateCartId() {
    let cartId = localStorage.getItem('cartId');
    if (cartId) return cartId;
    let docRef = await this.create();

    localStorage.setItem('cartId', docRef.id);
    return docRef.id; // Return a document reference
  }

  private create() {
    return this.db.collection('shopping-carts').add({
      dateCreated: new Date().getTime(),
    });
  }

  public async getCart() {
    const cartId = await this.getOrCreateCartId();
    return this.db.collection<ShoppingCart>('shopping-carts').doc(cartId).ref;
  }

  private getItem(cartId: string, productId: string) {
    return this.db
      .collection<ShoppingCartItem>('shopping-carts')
      .doc(cartId)
      .collection('items')
      .doc(productId).ref;
  }

  getQuantityForEachProduct(
    cartId: string,
    productId: string
  ): Observable<ShoppingCartItem> {
    return this.db
      .collection('shopping-carts')
      .doc(cartId)
      .collection('items')
      .doc(productId)
      .snapshotChanges()
      .pipe(
        map((snap) => {
          const data = snap.payload.data() as ShoppingCartItem;
          return data;
        })
      );
  }

  getCartTotalQuantity(cartDocId: string): Observable<number> {
    return this.db
      .collection('shopping-carts')
      .doc(cartDocId)
      .collection('items')
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          const shoppingCart = convertSnaps<ShoppingCartItem>(snaps);
          let totalCartQuantity = 0;
          shoppingCart.forEach((shoppingCartItem) => {
            totalCartQuantity += shoppingCartItem.quantity;
          });
          return totalCartQuantity;
        })
      );
  }

  getCartTotalPrice(cartDocId: string): Observable<number> {
    return this.db
      .collection('shopping-carts')
      .doc(cartDocId)
      .collection('items')
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          const shoppingCart = convertSnaps<ShoppingCartItem>(snaps);
          let totalCartPrice = 0;
          shoppingCart.forEach((shoppingCartItem) => {
            totalCartPrice +=
              shoppingCartItem.price * shoppingCartItem.quantity;
          });
          return totalCartPrice;
        })
      );
  }

  getShoppingCartItems(cartDocId: string): Observable<ShoppingCartItem[]> {
    return this.db
      .collection<ShoppingCartItem>('shopping-carts')
      .doc(cartDocId)
      .collection('items', (ref) => ref.where('quantity', '>', 0))
      .snapshotChanges()
      .pipe(map((snaps) => convertSnaps<ShoppingCartItem>(snaps)));
  }

  getClearShoppingCartItems(cartDocId: string): Observable<any> {
    return from(
      this.db
        .collection<ShoppingCartItem>('shopping-carts')
        .doc(cartDocId)
        .collection('items', (ref) => ref.where('quantity', '>', 0))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            snaps.forEach((snap) => {
              snap.payload.doc.ref.delete();
            });
          })
        )
    );
  }

  getRemoveShoppingCartItem(
    id: string,
    shoppingCartItemId: string
  ): Observable<any> {
    return from(
      this.db
        .collection('shopping-carts')
        .doc(id)
        .collection('items')
        .doc(shoppingCartItemId)
        .delete()
    );
  }

  clearShoppingCart(cartDocId: string): Observable<any> {
    return this.delelteShoppingCartItems(cartDocId);
  }

  private delelteShoppingCartItems(cartDocId: string) {
    return this.db
      .collection('shopping-carts')
      .doc(cartDocId)
      .collection('items')
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          snaps.forEach((snap) => {
            snap.payload.doc.ref.delete();
          });
        })
      );
  }
}
