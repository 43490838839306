import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Product } from '../../models/product';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { map, switchMap, first } from 'rxjs/operators';
import { ImageMetadata } from '../../interface/image-metadata';
import { FileService } from './file.service';
import { User } from '../../models/user';
import * as firebase from 'firebase';
import { convertSnaps } from './db-util';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private eventAuthError = new BehaviorSubject<string>('');
  eventAuthError$ = this.eventAuthError.asObservable();
  product$: Observable<Product>;
  
  constructor(
    private db: AngularFirestore,
    private fileService: FileService
  ) {}

  public addRealEstateHousesAndCondosForSaleProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addRealEstateHousesAndCondosForSaleProduct(product, user);
      })
    );
  }

  public addRealEstateLongTermRentalsForRentProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addRealEstateLongTermForRentProduct(product, user);
      })
    );
  }

  public addRealEstateShortTermForRentProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addRealEstateShortTermForRentProduct(product, user);
      })
    );
  }

  public addRealEstateStorageForRentProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addRealEstateStorageForRentProduct(product, user);
      })
    );
  }

  public addRealEstateParkingForRentProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addRealEstateParkingForRentProduct(product, user);
      })
    );
  }

  public addRealEstateCommercialAndOfficeForRentProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addRealEstateCommercialAndOfficeForRentProduct(product, user);
      })
    );
  }

  public addRealEstateLandForSaleProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addRealEstateLandForSaleProduct(product, user);
      })
    );
  }

  public addRealEstateCommercialAndOfficeForSaleProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addRealEstateCommercialAndOfficeForSaleProduct(product, user);
      })
    );
  }

  public addcarsAndVehiculesForCarsAndTrucksProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addCarsAndVehiculesForCarsAndTrucksProduct(product, user);
      })
    );
  }

  public addcarsAndVehiculesForVehiculePartsTiresAccessoriesProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addCarsAndVehiculesForVehiculePartsTiresAccessoriesProduct(product, user);
      })
    );
  }

  public addServiceWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = "";
        return this.addService(product, user);
      })
    );
  }

  public addFashionWomenClothingsProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addFashionWomenClothingsProduct(product, user);
      })
    );
  }

  public addFashionMenClothingsProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        if (!user.isBusinessOwner) {
          user.currency = '';
        }
        return this.addFashionMenClothingsProduct(product, user);
      })
    );
  }

  public addFashionKidsClothingsProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addFashionKidsClothingsProduct(product, user);
      })
    );
  }

  public addFashionJewelleryProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addFashionJewelleryProduct(product, user);
      })
    );
  }

  public addFashionShoesProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addFashionShoesProduct(product, user);
      })
    );
  }

  public addFashionWatchesProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addFashionWatchesProduct(product, user);
      })
    );
  }

  public addFashionHandbagsAccessoriesProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addFashionHandbagsAccessoriesProduct(product, user);
      })
    );
  }

  public addElectronicsComputersAndTabletsProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addElectronicsComputersAndTabletsProduct(product, user);
      })
    );
  }

  public addElectronicsSmartphonesProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addElectronicsSmartphonesProduct(product, user);
      })
    );
  }

  public addElectronicsTVandHomeEntertainmentProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addElectronicsTVandHomeEntertainmentProduct(product, user);
      })
    );
  }

  public addElectronicsRefurbishedProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addElectronicsRefurbishedProduct(product, user);
      })
    );
  }

  public addFurnituresLivingRoomProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addFurnituresLivingRoomProduct(product, user);
      })
    );
  }

  public addFurnituresBedroomProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addFurnituresBedroomProduct(product, user);
      })
    );
  }

  public addFurnituresDiningRoomProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addFurnituresDiningRoomProduct(product, user);
      })
    );
  }

  public addFurnituresKidsRoomProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addFurnituresKidsRoomProduct(product, user);
      })
    );
  }

  public addAppliancesKitchenProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addAppliancesKitchenProduct(product, user);
      })
    );
  }

  public addAppliancesLaundryProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addAppliancesKitchenProduct(product, user);
      })
    );
  }

  public addHealthBeautyFragrancesProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addHealthBeautyFragrancesProduct(product, user);
      })
    );
  }

  public addHealthBeautyBathBodyProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addHealthBeautyBathBodyProduct(product, user);
      })
    );
  }

  public addHealthBeautySkinCareProductWithImage(product: Product, imageMeta: ImageMetadata, user: User): Observable<Product> {
    return this.fileService.uploadImage(user.uid, imageMeta).pipe(
      switchMap((metadata) => {
        product.imageFirestoreIds.push(metadata.id);
        product.currentImageId = metadata.id;
        product.currency = user.currency;
        return this.addHealthBeautySkinCareProduct(product, user);
      })
    );
  }


  addMorePicturesProduct(
    product: Product,
    imageMeta: ImageMetadata
  ): Observable<Product> {
    return this.fileService
      .uploadImage(product.productOwnerId, imageMeta)
      .pipe(
        switchMap((metadata) => {
          product.currentImageId = metadata.id;
          return this.updateProduct(product.productOwnerId, product);
        })
      );
  }

  public updateProduct(
    currentUserId: string,
    product: Product
  ): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(currentUserId)
        .collection('products')
        .doc(product.productId)
        .update({
          currentImageId: product.currentImageId,
          imageFirestoreIds: firebase.default.firestore.FieldValue.arrayUnion(
            product.currentImageId
          ),
        })
    ).pipe(
      map(() => {
        return product;
      })
    );
  }

  public updateProductViewsCount(
    currentUserId: string,
    product: Product,
    viewsCount: number
  ): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(currentUserId)
        .collection('products')
        .doc(product.productId)
        .update({
          viewsCount: viewsCount + 1
        })
    ).pipe(
      map(() => {
        return product;
      })
    );
  }

  public addRealEstateShortTermForRentProduct(product: Product, user: User): Observable<Product> {
        
        return from(
          this.db
            .collection<Product>('users')
            .doc(user.uid)
            .collection('products')
            .add({
              name: product.name,
              topCategory: product.topCategory,
              subCategory: product.subCategory,
              newPrice: product.newPrice,
              oldPrice: product.oldPrice,
              currency: product.currency,
              discount: product.discount,
              bedrooms: product.bedrooms,
              bathrooms: product.bathrooms,
              furnishedYesOrNot: product.furnishedYesOrNot,
              size: product.size,
              typeSize: product.typeSize,
              quantity: product.quantity,
              description: product.description,
              propertyAddress: product.propertyAddress,
              currentImageId: product.currentImageId,
              imageFirestoreIds: product.imageFirestoreIds,
              imageStorageUrls: product.imageStorageUrls,
              createdTimestamp: product.createdTimestamp,
              productOwnerId: user.uid,
              productStatus: product.productStatus,
              expiredTimestamp: product.expiredTimestamp,
              viewsCount: product.viewsCount,
              collectorCategory: product.collectorCategory
            })
        ).pipe(
          map((productRef) => {
            product.productId = productRef.id;
            this.db
              .collection('users')
              .doc(user.uid)
              .collection('products')
              .doc(product.productId)
              .set(
                {
                  productId: product.productId,                 
                  productIdShortVerion: product.productId.substring(0, 7),
                  urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
                },
                { merge: true }
              );
            return product;
          })
        );    
  }

  public addRealEstateLongTermForRentProduct(product: Product, user: User): Observable<Product> {
        
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          currency: product.currency,
          discount: product.discount,
          bedrooms: product.bedrooms,
          bathrooms: product.bathrooms,
          moveInDatePicker: product.moveInDatePicker,
          furnishedYesOrNot: product.furnishedYesOrNot,
          airConditioningYesOrNot: product.airConditioningYesOrNot,
          agreementType: product.agreementType,
          unitType: product.unitType,
          isLaundryInUnit: product.isLaundryInUnit,
          isLaundryInBuilding: product.isLaundryInBuilding,
          isFridgeOrFreezer: product.isFridgeOrFreezer,
          isDiswasher: product.isDiswasher,
          isYard: product.isYard,
          isBalcony: product.isBalcony,
          isUtilityHydro: product.isUtilityHydro,
          isUtilityHead: product.isUtilityHead,
          isUtilityWater: product.isUtilityWater,
          isWifiCableOrTV: product.isWifiCableOrTV,
          isWifiInternet: product.isWifiInternet,
          size: product.size,
          typeSize: product.typeSize,
          parkingAllocNumber: product.parkingAllocNumber,
          quantity: product.quantity,
          description: product.description,
          propertyAddress: product.propertyAddress,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,                 
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addRealEstateCommercialAndOfficeForRentProduct(product: Product, user: User): Observable<Product> {
        
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          currency: product.currency,
          discount: product.discount,
          size: product.size,
          typeSize: product.typeSize,
          quantity: product.quantity,
          description: product.description,
          propertyAddress: product.propertyAddress,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,                 
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
            },
            { merge: true }
          );
        return product;
      })
    );    
}

public addRealEstateStorageForRentProduct(product: Product, user: User): Observable<Product> {
        
  return from(
    this.db
      .collection<Product>('users')
      .doc(user.uid)
      .collection('products')
      .add({
        name: product.name,
        topCategory: product.topCategory,
        subCategory: product.subCategory,
        newPrice: product.newPrice,
        oldPrice: product.oldPrice,
        currency: product.currency,
        discount: product.discount,
        size: product.size,
        typeSize: product.typeSize,
        quantity: product.quantity,
        description: product.description,
        propertyAddress: product.propertyAddress,
        currentImageId: product.currentImageId,
        imageFirestoreIds: product.imageFirestoreIds,
        imageStorageUrls: product.imageStorageUrls,
        createdTimestamp: product.createdTimestamp,
        productOwnerId: user.uid,
        productStatus: product.productStatus,
        expiredTimestamp: product.expiredTimestamp,
        viewsCount: product.viewsCount,
        collectorCategory: product.collectorCategory
      })
  ).pipe(
    map((productRef) => {
      product.productId = productRef.id;
      this.db
        .collection('users')
        .doc(user.uid)
        .collection('products')
        .doc(product.productId)
        .set(
          {
            productId: product.productId,                 
            productIdShortVerion: product.productId.substring(0, 7),
            urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
          },
          { merge: true }
        );
      return product;
    })
  );    
}

public addRealEstateParkingForRentProduct(product: Product, user: User): Observable<Product> {
        
  return from(
    this.db
      .collection<Product>('users')
      .doc(user.uid)
      .collection('products')
      .add({
        name: product.name,
        topCategory: product.topCategory,
        subCategory: product.subCategory,
        newPrice: product.newPrice,
        oldPrice: product.oldPrice,
        currency: product.currency,
        parkingAllocNumber: product.parkingAllocNumber,
        discount: product.discount,
        description: product.description,
        propertyAddress: product.propertyAddress,
        currentImageId: product.currentImageId,
        imageFirestoreIds: product.imageFirestoreIds,
        imageStorageUrls: product.imageStorageUrls,
        createdTimestamp: product.createdTimestamp,
        productOwnerId: user.uid,
        productStatus: product.productStatus,
        expiredTimestamp: product.expiredTimestamp,
        viewsCount: product.viewsCount,
        collectorCategory: product.collectorCategory
      })
  ).pipe(
    map((productRef) => {
      product.productId = productRef.id;
      this.db
        .collection('users')
        .doc(user.uid)
        .collection('products')
        .doc(product.productId)
        .set(
          {
            productId: product.productId,                 
            productIdShortVerion: product.productId.substring(0, 7),
            urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
          },
          { merge: true }
        );
      return product;
    })
  );    
}

  public addRealEstateHousesAndCondosForSaleProduct(product: Product, user: User): Observable<Product> {
        
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          currency: product.currency,
          discount: product.discount,
          bedrooms: product.bedrooms,
          bathrooms: product.bathrooms,
          size: product.size,
          typeSize: product.typeSize,
          quantity: product.quantity,
          description: product.description,
          propertyAddress: product.propertyAddress,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,                 
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
            },
            { merge: true }
          );
        return product;
      })
    );    
  }


  public addRealEstateLandForSaleProduct(product: Product, user: User): Observable<Product> {
        
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          currency: product.currency,
          discount: product.discount,
          size: product.size,
          typeSize: product.typeSize,
          description: product.description,
          quantity: product.quantity,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,                 
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addRealEstateCommercialAndOfficeForSaleProduct(product: Product, user: User): Observable<Product> {
        
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          currency: product.currency,
          discount: product.discount,
          description: product.description,
          quantity: product.quantity,
          size: product.size,
          typeSize: product.typeSize,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,                 
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addCarsAndVehiculesForCarsAndTrucksProduct(product: Product, user: User): Observable<Product> {
  
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          currency: product.currency,
          discount: product.discount,
          description: product.description,
          quantity: product.quantity,
          year: product.year,
          make: product.make,
          model: product.model,
          bodyType: product.bodyType,
          productCondition: product.productCondition,
          carsVehiculesMileage: product.carsVehiculesMileage,
          transmission: product.transmission,
          driveTrain: product.driveTrain,
          color: product.color,
          fuelType: product.fuelType,
          numOfDoors: product.numOfDoors,
          numOfSeats: product.numOfSeats,
          sunroof: product.sunroof,
          alloywheels: product.alloywheels,
          navsystem: product.navsystem,
          bluetooth: product.bluetooth,
          pushbuttonstart: product.pushbuttonstart,
          parkingassist: product.parkingassist,
          cruisecontrol: product.cruisecontrol,
          airconditioning: product.airconditioning,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,                 
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addCarsAndVehiculesForVehiculePartsTiresAccessoriesProduct(product: Product, user: User): Observable<Product> {
  
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          currency: product.currency,
          discount: product.discount,
          description: product.description,
          quantity: product.quantity,
          make: product.make,
          model: product.model,
          tireSize: product.tireSize,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,                 
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addService(product: Product, user: User): Observable<Product> {

    if (product.subCategory === 'Food & Catering') {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .add({
            name: product.name,
            topCategory: product.topCategory,
            subCategory: product.subCategory,
            description: product.description,
            currentImageId: product.currentImageId,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            currency: product.currency,
            discount: product.discount,
            imageFirestoreIds: product.imageFirestoreIds,
            imageStorageUrls: product.imageStorageUrls,
            createdTimestamp: product.createdTimestamp,
            productOwnerId: user.uid,
            productStatus: product.productStatus,
            expiredTimestamp: product.expiredTimestamp,
            viewsCount: product.viewsCount,
            collectorCategory: product.collectorCategory
          })
      ).pipe(
        map((productRef) => {
          product.productId = productRef.id;
          this.db
            .collection('users')
            .doc(user.uid)
            .collection('products')
            .doc(product.productId)
            .set(
              {
                productId: product.productId,                 
                productIdShortVerion: product.productId.substring(0, 7),
                urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
              },
              { merge: true }
            );
          return product;
        })
      );    
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .add({
            name: product.name,
            topCategory: product.topCategory,
            subCategory: product.subCategory,
            description: product.description,
            currentImageId: product.currentImageId,
            imageFirestoreIds: product.imageFirestoreIds,
            imageStorageUrls: product.imageStorageUrls,
            createdTimestamp: product.createdTimestamp,
            productOwnerId: user.uid,
            productStatus: product.productStatus,
            expiredTimestamp: product.expiredTimestamp,
            viewsCount: product.viewsCount,
            collectorCategory: product.collectorCategory
          })
      ).pipe(
        map((productRef) => {
          product.productId = productRef.id;
          this.db
            .collection('users')
            .doc(user.uid)
            .collection('products')
            .doc(product.productId)
            .set(
              {
                productId: product.productId,                 
                productIdShortVerion: product.productId.substring(0, 7),
                urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)                  
              },
              { merge: true }
            );
          return product;
        })
      );    
    }    
  }
  
  public addFashionJewelleryProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addFashionWomenClothingsProduct(product: Product, user: User): Observable<Product> {

    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          clothingSize: product.clothingSize,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addFashionMenClothingsProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          clothingSize: product.clothingSize,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addFashionKidsClothingsProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          clothingSize: product.clothingSize,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addFashionShoesProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          shoeSize: product.shoeSize,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addFashionWatchesProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          watchBrand: product.watchBrand,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addFashionHandbagsAccessoriesProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addElectronicsComputersAndTabletsProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          brand: product.brand,
          screenSize: product.screenSize,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addElectronicsTVandHomeEntertainmentProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          brand: product.brand,
          screenSize: product.screenSize,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addElectronicsRefurbishedProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          brand: product.brand,
          screenSize: product.screenSize,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addFurnituresLivingRoomProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addFurnituresBedroomProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addFurnituresDiningRoomProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addFurnituresKidsRoomProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addAppliancesKitchenProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addHealthBeautyFragrancesProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addHealthBeautyBathBodyProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addHealthBeautySkinCareProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  public addAppliancesLaundryProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }


  public addElectronicsSmartphonesProduct(product: Product, user: User): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(user.uid)
        .collection('products')
        .add({
          name: product.name,
          topCategory: product.topCategory,
          subCategory: product.subCategory,
          newPrice: product.newPrice,
          oldPrice: product.oldPrice,
          discount: product.discount,
          currency: product.currency,
          brand: product.brand,
          productCondition: product.productCondition,
          currentImageId: product.currentImageId,
          imageFirestoreIds: product.imageFirestoreIds,
          imageStorageUrls: product.imageStorageUrls,
          createdTimestamp: product.createdTimestamp,
          productOwnerId: user.uid,
          description: product.description,
          productStatus: product.productStatus,
          expiredTimestamp: product.expiredTimestamp,
          quantity: product.quantity,
          viewsCount: product.viewsCount,
          collectorCategory: product.collectorCategory
        })
    ).pipe(
      map((productRef) => {
        product.productId = productRef.id;
        this.db
          .collection('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .set(
            {
              productId: product.productId,
              productIdShortVerion: product.productId.substring(0, 7),
              urlTransition: product.topCategory + "/" + product.subCategory + "/" + product.productId.substring(0, 15)
            },
            { merge: true }
          );
        return product;
      })
    );    
  }

  updateFashionClothingProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition,
            clothingSize: product.clothingSize
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition,
            clothingSize: product.clothingSize
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } 
  }

  updateFashionJewelleryProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    }   
  }

  updateFashionShoesProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition,
            watchBrand: product.watchBrand
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition,
            watchBrand: product.watchBrand
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } 
  }

  updateFashionWatchesProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition,
            watchBrand: product.watchBrand
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition,
            watchBrand: product.watchBrand
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    }

    
  }

  updateFashionHandbagsAccessoriesProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {

    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition,
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition,
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    }
  }

  updateElectronicsComputersAndTabletsProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {

    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            brand: product.brand,
            screenSize: product.screenSize,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            brand: product.brand,
            screenSize: product.screenSize,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    }
    
  }

  updateElectronicsPhonesSmartphonesProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {

    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            brand: product.brand,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            brand: product.brand,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    }
   
  }

  updateElectronicsTVHomeEntertainmentProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {

    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            brand: product.brand,
            screenSize: product.screenSize,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            brand: product.brand,
            screenSize: product.screenSize,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    }

    
  }

  updateFurnituresProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    }
  }

  updateAppliancesProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    }
  }
  
  updateHealthBeautyProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            productCondition: product.productCondition
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    }
    
  }

  updateCarsVehiculeProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            year: product.year,
            make: product.make,
            model: product.model,
            bodyType: product.bodyType,
            productCondition: product.productCondition,
            carsVehiculesMileage: product.carsVehiculesMileage,
            transmission: product.transmission,
            driveTrain: product.driveTrain,
            color: product.color,
            fuelType: product.fuelType,
            numOfDoors: product.numOfDoors,
            numOfSeats: product.numOfSeats,
            sunroof: product.sunroof,
            alloywheels: product.alloywheels,
            navsystem: product.navsystem,
            bluetooth: product.bluetooth,
            pushbuttonstart: product.pushbuttonstart,
            parkingassist: product.parkingassist,
            cruisecontrol: product.cruisecontrol,
            airconditioning: product.airconditioning
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            year: product.year,
            make: product.make,
            model: product.model,
            bodyType: product.bodyType,
            productCondition: product.productCondition,
            carsVehiculesMileage: product.carsVehiculesMileage,
            transmission: product.transmission,
            driveTrain: product.driveTrain,
            color: product.color,
            fuelType: product.fuelType,
            numOfDoors: product.numOfDoors,
            numOfSeats: product.numOfSeats,
            sunroof: product.sunroof,
            alloywheels: product.alloywheels,
            navsystem: product.navsystem,
            bluetooth: product.bluetooth,
            pushbuttonstart: product.pushbuttonstart,
            parkingassist: product.parkingassist,
            cruisecontrol: product.cruisecontrol,
            airconditioning: product.airconditioning
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    }
  }

  updateRealEstateLongTermForRentProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            bedrooms: product.bedrooms,
            bathrooms: product.bathrooms,
            moveInDatePicker: product.moveInDatePicker,
            furnishedYesOrNot: product.furnishedYesOrNot,
            airConditioningYesOrNot: product.airConditioningYesOrNot,
            agreementType: product.agreementType,
            unitType: product.unitType,
            isLaundryInUnit: product.isLaundryInUnit,
            isLaundryInBuilding: product.isLaundryInBuilding,
            isFridgeOrFreezer: product.isFridgeOrFreezer,
            isDiswasher: product.isDiswasher,
            isYard: product.isYard,
            isBalcony: product.isBalcony,
            isUtilityHydro: product.isUtilityHydro,
            isUtilityHead: product.isUtilityHead,
            isUtilityWater: product.isUtilityWater,
            isWifiCableOrTV: product.isWifiCableOrTV,
            isWifiInternet: product.isWifiInternet,
            size: product.size,
            typeSize: product.typeSize,
            parkingAllocNumber: product.parkingAllocNumber   
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            year: product.year,
            make: product.make,
            model: product.model,
            bodyType: product.bodyType,
            productCondition: product.productCondition,
            carsVehiculesMileage: product.carsVehiculesMileage,
            transmission: product.transmission,
            driveTrain: product.driveTrain,
            color: product.color,
            fuelType: product.fuelType,
            numOfDoors: product.numOfDoors,
            numOfSeats: product.numOfSeats,
            sunroof: product.sunroof,
            alloywheels: product.alloywheels,
            navsystem: product.navsystem,
            bluetooth: product.bluetooth,
            pushbuttonstart: product.pushbuttonstart,
            parkingassist: product.parkingassist,
            cruisecontrol: product.cruisecontrol,
            airconditioning: product.airconditioning
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } 
  }

  updateRealEstateShortTermForRentProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            bedrooms: product.bedrooms,
            bathrooms: product.bathrooms,
            furnishedYesOrNot: product.furnishedYesOrNot,
            size: product.size,
            typeSize: product.typeSize, 
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            bedrooms: product.bedrooms,
            bathrooms: product.bathrooms,
            furnishedYesOrNot: product.furnishedYesOrNot,
            size: product.size,
            typeSize: product.typeSize        
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } 
  }

  updateRealEstateStorageForRentProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            size: product.size,
            typeSize: product.typeSize, 
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            size: product.size,
            typeSize: product.typeSize        
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } 
  }

  updateRealEstateParkingForRentProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            parkingAllocNumber: product.parkingAllocNumber,
            description: product.description,
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            parkingAllocNumber: product.parkingAllocNumber,
            description: product.description   
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } 
  }

  updateRealEstateHousesCondosForSaleProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            bedrooms: product.bedrooms,
            bathrooms: product.bathrooms,
            size: product.size,
            typeSize: product.typeSize, 
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            bedrooms: product.bedrooms,
            bathrooms: product.bathrooms,
            size: product.size,
            typeSize: product.typeSize        
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } 
  }

  updateRealEstateLandForSaleProduct(product: Product, user: User, isDirty: boolean): Observable<Product> {
    if (isDirty) {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            newPrice: product.newPrice,
            oldPrice: product.oldPrice,
            discount: product.discount,
            quantity: product.quantity,
            description: product.description,
            size: product.size,
            typeSize: product.typeSize, 
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } else {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            quantity: product.quantity,
            description: product.description,
            size: product.size,
            typeSize: product.typeSize        
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
    } 
  }

  updateService(product: Product, user: User): Observable<Product> {
      return from(
        this.db
          .collection<Product>('users')
          .doc(user.uid)
          .collection('products')
          .doc(product.productId)
          .update({
            name: product.name,
            description: product.description
          })
      ).pipe(
        map(() => {
          return product;
        })
      );
  }

  
  public editProduct(
    product: Product,
    currentUserId: string
  ): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(currentUserId)
        .collection('products')
        .doc(product.productId)
        .update({
          name: product.name,
          newPrice: product.newPrice,
          category: product.category,
          description: product.description,
          discount: product.discount,
          oldPrice: product.oldPrice,
        })
    ).pipe(
      map(() => {
        return product;
      })
    );
  }
  

  getAllProductsDataInSession(userId: string): Observable<Product[]> {
    return this.db
        .collection('users')
        .doc(userId)
        .collection('products', (ref) => ref.where('productStatus', '==', 3))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );
  }
  
  getAllProductsRequestForReview(userId: string): Observable<Product[]> {
    return this.db
        .collection('users')
        .doc(userId)
        .collection('products', (ref) => ref.where('productStatus', '==', 2))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );
  }  
      

  getProductsByTopCatogory(userId: string, topCategory: string): Observable<Product[]> {
    switch (topCategory) {
      case 'Sell':
        return this.db
        .collection('users')
        .doc(userId)
        .collection('products', (ref) => ref.where('topCategory', '==', 20)
        .where('productStatus', '==', 3))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );
       
      case 'Cars & Vehicules':
        return this.db
        .collection('users')
        .doc(userId)
        .collection('products', (ref) => ref.where('topCategory', '==', 60)
        .where('productStatus', '==', 3))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );
    
      case 'Real Estate':
        return this.db
        .collection('users')
        .doc(userId)
        .collection('products', (ref) => ref.where('topCategory', '==', 80)
        .where('productStatus', '==', 3))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );
        
      case 'Jobs':
        return this.db
        .collection('users')
        .doc(userId)
        .collection('products', (ref) => ref.where('topCategory', '==', 100)
        .where('productStatus', '==', 3))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );

      case 'Services':
        return this.db
        .collection('users')
        .doc(userId)
        .collection('products', (ref) => ref.where('topCategory', '==', 130)
        .where('productStatus', '==', 3))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );

      case 'Livestock':
        return this.db
        .collection('users')
        .doc(userId)
        .collection('products', (ref) => ref.where('topCategory', '==', 150)
        .where('productStatus', '==', 3))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );

      case 'Vaction Rentals':
        return this.db
        .collection('users')
        .doc(userId)
        .collection('products', (ref) => ref.where('topCategory', '==', 200)
        .where('productStatus', '==', 3))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );

      default:
        break;
    }
  }

  getProductsBySubCatogory(userId: string, subCategory: string): Observable<Product[]> {
    return this.db
    .collection('users')
    .doc(userId)
    .collection('products', (ref) => ref.where('subCategory', '==', subCategory)
    .where('productStatus', '==', 3))
    .snapshotChanges()
    .pipe(
      map((snaps) => {
        const products = convertSnaps<Product>(snaps);
        for (let i = 0; i < products.length; i++) {
          for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
            const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
              '?'
            );
            products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
              j
            ].substring(0, indexOfFirst);
          }
        }
        return products;
      }),
      first()
    );
  }

  getProductsBySelectedTopCatogory(userId: string, topCategory: number): Observable<Product[]> {
    return this.db
    .collection('users')
    .doc(userId)
    .collection('products', (ref) => ref.where('topCategory', '==', topCategory)
    .where('productStatus', '==', 3))
    .snapshotChanges()
    .pipe(
      map((snaps) => {
        const products = convertSnaps<Product>(snaps);
        for (let i = 0; i < products.length; i++) {
          for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
            const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
              '?'
            );
            products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
              j
            ].substring(0, indexOfFirst);
          }
        }
        return products;
      }),
      first()
    );
  }

  getProductsOwner(userId: string): Observable<Product[]> {
      return this.db
        .collection('users')
        .doc(userId)
        .collection('products', (ref) => ref.where('productStatus', '==', 3))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );
  }

  getProductsOwnerBySubCategory(userId: string, subCategory: string): Observable<Product[]> {
    return this.db
      .collection('users')
      .doc(userId)
      .collection('products', (ref) => ref.where('productStatus', '==', 3)
      .where('subCategory', '==', subCategory))
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          const products = convertSnaps<Product>(snaps);
          for (let i = 0; i < products.length; i++) {
            for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
              const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                '?'
              );
              products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                j
              ].substring(0, indexOfFirst);
            }
          }
          return products;
        })
      );
  }

  getBOProducts(userId: string, subCategory: string): Observable<Product[]> {
    if (subCategory.toLowerCase() == 'all') {
      return this.db
        .collection('users')
        .doc(userId)
        .collection('products')
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );
    } else {
      return this.db
        .collection('users')
        .doc(userId)
        .collection('products', (ref) => ref.where('subCategory', '==', subCategory))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            const products = convertSnaps<Product>(snaps);
            for (let i = 0; i < products.length; i++) {
              for (let j = 0; j < products[i].imageStorageUrls.length; j++) {
                const indexOfFirst = products[i].imageStorageUrls[j].indexOf(
                  '?'
                );
                products[i].imageStorageUrls[j] = products[i].imageStorageUrls[
                  j
                ].substring(0, indexOfFirst);
              }
            }
            return products;
          })
        );
    }
  }

  getPublisedProductPerCategory(userId: string, collectorCategory: string): Observable<Product[]> {
    return this.db
    .collection('users')
    .doc(userId)
    .collection('products', (ref) => ref.where('productOwnerId', '==', userId)
    .where('collectorCategory', '==', collectorCategory)
    .where('productStatus', '==', 3))
    .snapshotChanges()
    .pipe(
      map((snaps) => {
        const products = convertSnaps<Product>(snaps);
        return products;
      })
    );
  }

  getRequestedReviewProducts(userId: string): Observable<Product[]> {
    return this.db
    .collection('users')
    .doc(userId)
    .collection('products', (ref) => ref.where('productOwnerId', '==', userId)
    .where('productStatus', '==', 2))
    .snapshotChanges()
    .pipe(
      map((snaps) => {
        const products = convertSnaps<Product>(snaps);
        return products;
      })
    );
  }

  findProductByUrl(
    boId: string,
    productUrlTransition: string
  ): Observable<Product> {
    return this.db
      .collection<Product>('users')
      .doc(boId)
      .collection('products', (ref) =>
        ref.where('urlTransition', '==', productUrlTransition)
      )
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          const products = convertSnaps<Product>(snaps);
          return products.length == 1 ? products[0] : undefined;
        }),
        first()
      );
  }

  public getProductById(
    currentUserId: string,
    productId: string
  ): Observable<Product> {
    return from(
      this.db
        .collection<Product>('users')
        .doc(currentUserId)
        .collection('products')
        .doc(productId)
        .snapshotChanges()
        .pipe(
          map((snap) => {
            const product = snap.payload.data() as Product;
            return product;
          }),
          first()
        )
    );
  }

  getProducts(currentUserId: string): Observable<Product[]> {
    return this.db.collection<User>('users').doc(currentUserId).collection('products', ref => ref.where('productStatus', '==', 3)).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
            const data = a.payload.doc.data() as Product
            const id = a.payload.doc.id
            return { id, ...data }
        }))       
      );
  }
  
  updateProductStatus(userId: string, productId: string): Observable<any> {
    return from(this.db.collection<User>('users').doc(userId).collection('products').doc(productId).update({productStatus: 2}));
  }

  updateProductStatusApprovalForReview(userId: string, productId: string): Observable<any> {
    return from(this.db.collection<User>('users').doc(userId).collection('products').doc(productId).update({productStatus: 3}));
  }

  updateProductStatusWithExpiredDate(userId: string, productId: string): Observable<any> {
    return from(this.db.collection<User>('users').doc(userId).collection('products').doc(productId).update({productStatus: 5}));
  }



}
