import { Injectable } from '@angular/core';
import { FileMetadata } from '../../interface/fileMetadata';
import { Observable, from, defer } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { ImageMetadata } from '../../interface/image-metadata';

@Injectable({
  providedIn: 'root'
})
export class FileService {

    constructor(private angularFireStorage: AngularFireStorage,
        private db: AngularFirestore) { }

    uploadImage(currentUserId: string, imageMetadata: ImageMetadata): Observable<FileMetadata> {
        if (imageMetadata.imageBlob) {
            const fileToUpload = new File([imageMetadata.imageBlob], imageMetadata.fileMeta.name, { type: imageMetadata.fileMeta.type });
            return this.upload(currentUserId, fileToUpload);
        }
    }

    upload(currentUserId: string, file: File): Observable<FileMetadata> {
        const meta = <FileMetadata>{
                name: file.name,
                type: file.type,
                size: file.size,
                lastModified: file.lastModified
        };
        if (file) {
            return this.addFileMetadata(currentUserId, meta).pipe(
                switchMap(fileMeta => {
                    const filePath = currentUserId + '/product-pictures/' + fileMeta.id;
                    return defer(() =>
                        this.angularFireStorage.ref(filePath)
                        .put(file)
                            .then()
                    ).pipe(
                        map(fileRef => {
                            return fileMeta;
                        })
                    )
                })    
            );
        }
    }

    addFileMetadata(currentUserId: string, meta: FileMetadata): Observable<FileMetadata> {
        return defer(() =>
            this.db.collection('users').doc(currentUserId).collection('files').add(meta))
            .pipe(
                map(documentRef => {
                    meta.id = documentRef.id;
                    return meta;
                })
            );
    }
 
    getDownloadFileUrl(currentUserId: string, firestoreImageId: string): Observable<any> {
        return this.angularFireStorage.ref(currentUserId + '/product-pictures/' + firestoreImageId).getDownloadURL();
    }
   
}
