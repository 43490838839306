<div class="top-menu">

    <ng-template #anonymousUser>
        <a mat-button routerLink="/">Home</a>
        <a mat-button routerLink="/find-store">Find Store<span>&rlm;</span></a>
        <a mat-button routerLink="/auth">Login</a>
        <a mat-button routerLink="/auth/register">register</a>
        <a mat-button routerLink="/contact">Contact<span>&rlm;</span></a>
        <a mat-button [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
            <span class="flag-menu-title">{{siteLanguage}}</span>
            <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
        </a>
        <mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
            <span (mouseleave)="langMenuTrigger.closeMenu()">
                <a mat-menu-item matTooltip="switch to English" matTooltipPosition="left" (click)="changeSiteLanguage('en')">English</a>
                <a mat-menu-item matTooltip="passer au Français" matTooltipPosition="left" (click)="changeSiteLanguage('fr')">Français</a>
                <a mat-menu-item matTooltip="u beddel Somali" matTooltipPosition="left" (click)="changeSiteLanguage('so')">Somali</a>
            </span>
        </mat-menu>
    </ng-template>


    <div *ngIf="isEmailVerified$ | async; else anonymousUser">
        <div *ngIf="auth.user$ | async as user">

            <a mat-button routerLink="/">Home</a>
            <a mat-button routerLink="/find-store">Find Store<span>&rlm;</span></a>
            <a mat-button (click)="logout()">Logout</a>
            <a mat-button routerLink="/contact">Contact<span>&rlm;</span></a>
            
            <a mat-button [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
                <span class="flag-menu-title">{{siteLanguage}}</span>
                <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
            </a>
            <mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
                <span (mouseleave)="langMenuTrigger.closeMenu()">
                    <a mat-menu-item matTooltip="switch to English" matTooltipPosition="left" (click)="changeSiteLanguage('en')">English</a>
                    <a mat-menu-item matTooltip="passer au Français" matTooltipPosition="left" (click)="changeSiteLanguage('fr')">Français</a>
                    <a mat-menu-item matTooltip="u beddel Somali" matTooltipPosition="left" (click)="changeSiteLanguage('so')">Somali</a>
                </span>
            </mat-menu>

            <a mat-button [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger">
                <mat-icon class="mat-icon-sm account-icon">person</mat-icon> <span fxShow="false" fxShow.gt-sm>{{user.fname}}</span> <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon> 
            </a>

            <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account">
                <span (mouseleave)="accountMenuTrigger.closeMenu()">
                    <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
                        <img src="assets/images/others/user.jpg" alt="user-image" width="50">
                        <!-- <p>{{user.displayName}}<br></p> -->
                    </div>
                    <div class="divider"></div>
                    <a mat-menu-item routerLink="/account">
                        <mat-icon class="mat-icon-sm">settings</mat-icon>
                        <span>Your Account Dashboard</span>
                    </a>
                </span>
            </mat-menu>
        </div>
    </div>

</div> 