import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { CanReadGuard } from './shared/guard/can-read-guard';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('./pages/auth/auth.module').then((m) => m.AuthModule),
        data: { breadcrumb: 'Authentication' },
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./pages/account/account.module').then((m) => m.AccountModule),
        data: { breadcrumb: 'Account Settings' },
        canActivate: [CanReadGuard],
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./pages/contact/contact.module').then((m) => m.ContactModule),
        data: { breadcrumb: 'Contact' },
      },
      {
        path: 'find-store',
        loadChildren: () =>
          import('./pages/find-store/find-store.Module').then((m) => m.FindStoreModule),
        data: { breadcrumb: 'Find Store' },
      },
      {
        path: 'cart',
        loadChildren: () =>
          import('./pages/cart/cart.module').then((m) => m.CartModule),
        data: { breadcrumb: 'Cart' },
      },
      {
        path: 'checkout',
        loadChildren: () =>
          import('./pages/checkout/checkout.module').then(
            (m) => m.CheckoutModule
          ),
        data: { breadcrumb: 'Checkout' },
        canActivate: [CanReadGuard],
      },
      {
        path: 'products/selectedProduct',
        loadChildren: () =>
          import('./pages/products/products.module').then((m) => m.ProductsModule)
      },
      {
        path: 'srchByCategory',
        loadChildren: () =>
          import('./pages/p-menu-srch/p-menu-srch.module').then(
            (m) => m.PMenuSrchModule
          ),
      },
      {
        path: 'p-srch/results',
        loadChildren: () =>
          import('./pages/p-srch/p-srch.module').then((m) => m.PSrchModule)
      },
      {
        path: 'owner',
        loadChildren: () =>
          import('./pages/business/business.module').then(
            (m) => m.BusinessModule
          )
      },
    ],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
  // useHash: true
});
