import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'src/app/models/product';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(products : Product[], filterString: string, propName:string): Product[] {
    const results:Product[] = [];
    if(!products || filterString==='' || propName ===''){
      return products;
    }
    products.forEach((product:any)=>{
      if(product[propName].trim().toLowerCase().includes(filterString.toLowerCase())){
        results.push(product);
      }
    });
    return results;
  }

}
