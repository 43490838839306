import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { Product } from '../../models/product';
import { map, tap, switchMap } from 'rxjs/operators';
import * as firebase from 'firebase';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class ManageProductService {

    products: Observable<Product[]>;
    
    constructor(private readonly db: AngularFirestore) { }

    getProducts(currentUserId: string): Observable<Product[]> {
        this.products = this.db.collection<User>('users').doc(currentUserId).collection('products').snapshotChanges()
            .pipe(
                map(snaps => snaps.map(snap => {
                    const data = snap.payload.doc.data() as Product
                    const id = snap.payload.doc.id
                    return { id, ...data }
                }))
        );
        return this.products;
    }

    deleteProduct(currentUserId: string, productId: string): Observable<boolean> {
        return from (this.db.collection<Product>('users').doc(currentUserId).collection('products').doc(productId).delete())
            .pipe(
                map(() => true)
            ) 
    }

    /*
    deleteMessage(messageId: string): Observable<boolean> {
        return from (this.db.collection<Message>('messages').doc(messageId).delete())
          .pipe(
            map(() => {
              return true;
            })
          );
      */
      

   
}
