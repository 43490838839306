import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ImageMetadata } from '../../interface/image-metadata';
import { FileService } from './file.service';
import { Banner } from '../../models/banner';
import { User } from 'src/app/models/user';
import { convertSnaps } from './db-util';

@Injectable({
    providedIn: 'root'
})
export class BannersService {

    private eventAuthError = new BehaviorSubject<string>("");
    eventAuthError$ = this.eventAuthError.asObservable();

    constructor(private db: AngularFirestore, private router: Router,
        private fileService: FileService) { }

    addBannerWithImage(banner: Banner, imageMeta: ImageMetadata, currentUserId: string): Observable<Banner> {
        return this.fileService.uploadImage(currentUserId, imageMeta).pipe(
          switchMap((metadata) => {
            banner.imageFirestoreId = metadata.id;
            banner.currentImageId = metadata.id;
            return this.addBannerImage(banner, currentUserId);
          })
        );
    }

    public addBannerImage(banner: Banner, currentUserId: string): Observable<Banner> {    
        return from(
          this.db
            .collection<User>('users')
            .doc(currentUserId)
            .collection('banners')
            .add({
              currentImageId: banner.currentImageId,
              imageFirestoreId: banner.imageFirestoreId,
              imageStorageUrl: banner.imageStorageUrl,
              createdTimestamp: banner.createdTimestamp
            })
        ).pipe(
          map((bannerRef) => {
            banner.bannerId = bannerRef.id;
            this.db
              .collection('users')
              .doc(currentUserId)
              .collection('banners')
              .doc(banner.bannerId)
              .set(
                {
                  bannerId: banner.bannerId                
                },
                { merge: true }
              );
            return banner;
          })
        );    
    }

    getAllMyBanners(currentUserId: string): Observable<Banner[]> {
        return this.db.collection<User>('users').doc(currentUserId).collection<Banner>('banners').snapshotChanges()
            .pipe(
                map(snaps => {
                  const banners = convertSnaps<Banner>(snaps);
                  for (let i = 0; i < banners.length; i++) {
                    banners[i].imageStorageUrl = banners[i].imageStorageUrl.substring(0, banners[i].imageStorageUrl.indexOf('?'));
                  }
                  return banners;
                }),
                first()
        );
    }

    getAllMyBannersUrls(currentUserId: string): Observable<string[]> {
      return this.db.collection<User>('users').doc(currentUserId).collection<Banner>('banners').snapshotChanges()
          .pipe(
              map(snaps => {
                const banners = convertSnaps<Banner>(snaps);
                const bannerUrls: string[] = [];
                for (let i = 0; i < banners.length; i++) {
                  const url = banners[i].imageStorageUrl.substring(0, banners[i].imageStorageUrl.indexOf('?')); 
                  bannerUrls.push(url); 
                }
                return bannerUrls;
              })
      );
  }

    deleteBanner(currentUserId: string, bannerId: string): Observable<any> {
        return from(this.db.collection('users').doc(currentUserId).collection('banners').doc(bannerId).delete());
    }
    

}

