export class Product {
    productId?: string;
    name?: string;
    newPrice?: number;
    oldPrice?: number;
    discount?: number;
    currency?: string;
    category?: string;
    description?: string;
    currentImageId?: string;
    imageFirestoreIds?: string[] = [];
    imageStorageUrls?: string[] = [];
    createdTimestamp?: string;
    expiredTimestamp?: string;
    productIdShortVerion?: string;
    quantity?: number;
    urlTransition?: string;
    productOwnerId?: string;
    viewsCount?: number;
    topCategory?: number;
    collectorCategory?: string; 
    subCategory?: string;
    productStatus?: ProductStatus;
    serviceDescription?: string;
    serviceAboutUs?: string;

    //* Cars & Vehicules
    year?: number;
    make?: string;
    model?: string;
    bodyType?: string;
    carrier?: string;
    carsVehiculesMileage?: number;
    transmission?: string;
    driveTrain?: string;
    color?: string;
    fuelType?: string;
    numOfDoors?: number;
    numOfSeats?: number;
    tireSize?: string;
    watchBrand?: string;
    sunroof?: boolean;
    alloywheels?: boolean;
    navsystem?: boolean;
    bluetooth?: boolean;
    pushbuttonstart?: boolean;
    parkingassist?: boolean;
    cruisecontrol?: boolean;
    airconditioning?: boolean;
    brand?: string;
    screenSize?: string;
    productCondition?: string;
    clothingSize?: string;
    shoeSize?: string;
    bedrooms?: string;
    bathrooms?: string;
    size?: number;
    typeSize?: string;
    unitType?: string;
    agreementType?: string;
    moveInDatePicker?: string;
    furnishedYesOrNot?: string;
    airConditioningYesOrNot?: string;
    isLaundryInUnit?:boolean;
    isLaundryInBuilding?:boolean;
    isDiswasher?: boolean;
    isFridgeOrFreezer?: boolean;
    isYard?: boolean;
    isBalcony?: boolean;
    isUtilityHydro?: boolean;
    isUtilityHead?: boolean;
    isUtilityWater?: boolean;
    isWifiCableOrTV?: boolean;
    isWifiInternet?: boolean;
    parkingAllocNumber?: number;
    propertyAddress?: PropertyAddress;

    constructor() { }

}

export class PropertyAddress {
    addressL1: string;
    city: string;
    countryOrRegion: string;
    provinceOrState?: string;
    postalCodeOrZipCode?: string;

    constructor() { }
}

export enum ProductStatus {
    unpublished   = 1,
    pending       = 2,
    publish       = 3,
    rejected      = 4,
    expired       = 5
}

