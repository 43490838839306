import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { Order } from '../../models/order';
import { convertSnaps } from './db-util';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
    
    orders: any[];
    constructor(private db: AngularFirestore) { }

    saveOrder(order: Order): Observable<Order> {
        return from(this.db.collection('orders').add(order))
            .pipe(
                map(orderRef => {
                    order.orderId = orderRef.id;
                    this.db.collection('orders').doc(order.orderId).update({
                            orderId: orderRef.id,
                            orderIdShortVersion: orderRef.id.substring(0, 7)
                        }), 
                        { merge: true }
                        return order;
            }));
    }

    getAllMyOrders(userId: String): Observable<any[]> {
        return this.db.collection('orders', ref => ref.where('customerId', '==', userId)).snapshotChanges()
            .pipe(
                map(snaps => snaps.map(snap => {
                    const data = snap.payload.doc.data() as any
                    const id = snap.payload.doc.id
                    return { id, ...data }
                }))
        );
    }

    getBusinessOwnerOrders(userId: String): Observable<any[]> {
        return this.db.collection('orders', ref => ref.where('businessOwnerId', '==', userId)).snapshotChanges()
            .pipe(
                map(snaps => snaps.map(snap => {
                    const data = snap.payload.doc.data() as any
                    const id = snap.payload.doc.id
                    return { id, ...data }
                }))
            );
    }

    findOrderByUrl(orderId: string): Observable<any> {
        return this.db.collection<any>('orders').doc(orderId).snapshotChanges()
            .pipe(
                map(order => {
                    const data = order.payload.data();
                    console.log('step2');
                    return data;
                })
                
            );
    }

    updateOrderStatusByCustomer(orderId: string, status: string, note: string, confirmationNumber: string) {
        return this.db.collection('orders').doc(orderId).update(
            {
                status: status,
                note: note,
                confirmationNumber: confirmationNumber
            }
        );
    }

    updateOrderStatusByBO(orderId: string, status: string, note: string) {
        return this.db.collection('orders').doc(orderId).update(
            {
                status: status,
                note: note
            }
        );
    }

}
