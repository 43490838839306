import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  HostListener,
  ChangeDetectorRef
} from "@angular/core";
import { Router } from "@angular/router";
import { Settings, AppSettings } from "../app.settings";
import { SidenavMenuService } from "../theme/components/sidenav-menu/sidenav-menu.service";
import { ShoppingCartService } from "../shared/services/shopping-cart.service";
import { Subscription, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { ShoppingCartItem } from "../models/shopping-cart-item";
import { DocumentReference } from "@angular/fire/firestore/interfaces";
import { MessengerService } from "../shared/services/messenger.service";
import { Category } from "../interface/category";
import { FormControl } from "@angular/forms";
import { AuthService } from "../shared/services/auth.service";
import { GenericService } from "../shared/services/generic.service";
import { EventService } from "../shared/services/event.service";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  providers: [SidenavMenuService]
})
export class PagesComponent implements OnInit, OnDestroy {
  public showBackToTop: boolean = false;
  public sidenavMenuItems: Array<any>;
  public totalCartQuantity: number;
  public totalCartPrice: number;
  public shoppingCart: ShoppingCartItem[] = [];
  public subscription1: Subscription;
  public subscription2: Subscription;
  public subscription3: Subscription;
  public subscription4: Subscription;
  public subscription5: Subscription;
  public cartDocRef: DocumentReference;
  public categories: Category[];
  public category: Category;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  public isDisabled: boolean = true;
  options: string[] = []; // dynamically populated from back end
  showShoppingCart: boolean;
  public currency: string = '';

  @ViewChild("sidenav", { static: true })
  sidenav: any;

  public settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public sidenavMenuService: SidenavMenuService,
    public shoppingCartService: ShoppingCartService,
    private messengerService: MessengerService,
    private cdRef: ChangeDetectorRef,
    public router: Router,
    public auth: AuthService,
    private eventService: EventService,
    private genericService: GenericService
  ) {
    this.settings = this.appSettings.settings;
  }

  async ngOnInit() {
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    
    this.messengerService.getShoppingCartDisplayed()
          .subscribe(showShoppingCart => this.showShoppingCart = showShoppingCart);

    this.cartDocRef = await this.shoppingCartService.getCart();
    this.getTotalCartQuantity(this.cartDocRef.id);
    this.getTotalCartPrice(this.cartDocRef.id);
    this.getShoppingCartItems(this.cartDocRef.id);
    this.getCategories();
  }

  private getTotalCartQuantity(cartDocId: string) {
    this.subscription1 = this.shoppingCartService
      .getCartTotalQuantity(cartDocId)
      .pipe(
        map(number => {
          this.totalCartQuantity = number;
        })
      )
      .subscribe();
  }

  private getTotalCartPrice(cartDocId: string) {
    this.subscription2 = this.shoppingCartService
      .getCartTotalPrice(cartDocId)
      .pipe(
        map(number => {
          this.totalCartPrice = number;
        })
      )
      .subscribe();
  }

  private getShoppingCartItems(cartDocId: string) {
    this.subscription3 = this.shoppingCartService
      .getShoppingCartItems(cartDocId)
      .pipe(
        map(snaps => {
          this.shoppingCart = snaps;
        })
      )
      .subscribe();
  }

  public remove(shoppingCartItem: ShoppingCartItem) {
    this.subscription4 = this.shoppingCartService
      .getRemoveShoppingCartItem(this.cartDocRef.id, shoppingCartItem.productId)
      .subscribe(() => {
        this.messengerService.sendMessage(true);
      });
  }

  public clear() {
    this.shoppingCartService
      .clearShoppingCart(this.cartDocRef.id)
      .pipe(take(1))
      .subscribe();
  }

  getCategories() {
    this.subscription5 = this.genericService
      .getCategories()
      .pipe(
        map(data => {
          this.categories = data;
          this.category = data[0];
        })
      )
      .subscribe();
  }

  public changeCategory(event){
    if(event.target){
      this.category = this.categories.filter(category => category.name == event.target.innerText)[0];
      sessionStorage.setItem('changeCategory', this.category.name); 
    }
    if(window.innerWidth < 960){
      this.stopClickPropagate(event);
    } 
  }

  stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  search() {  
      if (this.myControl.valid) {
        sessionStorage.removeItem('searchString');
        
        sessionStorage.setItem('searchString', this.myControl.value);
        if (location.pathname !== '/p-srch/results') {
          this.router.navigate(['p-srch/results']);
        } else {
          // sessionStorage.setItem('changeCategory', 'All Categories'); 
          location.reload();
        }
      }
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event) {
    $event.target.documentElement.scrollTop > 300
      ? (this.showBackToTop = false)
      : (this.showBackToTop = false);
  }

  ngOnDestroy(): void {
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    } else if(this.subscription2) {
      this.subscription2.unsubscribe();
    }  else if(this.subscription3) {
      this.subscription3.unsubscribe();
    }  else if(this.subscription4) {
      this.subscription4.unsubscribe();
    }  else if(this.subscription5) {
      this.subscription5.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

}
