import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore} from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of} from 'rxjs';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { first, map} from 'rxjs/operators';
import { User } from '../../models/user';
import { HttpClient } from '@angular/common/http';
import { convertSnaps } from './db-util';
import * as firebase from 'firebase';


@Injectable({
  providedIn: 'root'
})
export class BusinessService {
 
  user$: Observable<User>;

  constructor(private afAuth: AngularFireAuth,
        private db: AngularFirestore,
        private router: Router,
        public http: HttpClient,
        private route: ActivatedRoute) {

        // Get auth data, then get firestore user document || null
        this.user$ = afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    return this.db.doc<User>(`users/${user.uid}`).valueChanges();
                }
                else {
                    return of(null);
                }
            })
        );
    }

  getBusiness(currentUserId: string): Observable<User> {
    this.user$ = this.db.collection<User>('users').doc(currentUserId).snapshotChanges()
        .pipe(
            map(snap => {
                const data = snap.payload.data() as User;
                return data;
            })
    );
    return this.user$;
  }

  findBusinessByUrl(businessUrlTransition: string): Observable<User> {
    return this.db.collection<User>('users', ref => ref.where('urlTransition', '==', businessUrlTransition)).snapshotChanges()
        .pipe(
            map(snaps => {
                const users = convertSnaps<User>(snaps);
                return users.length == 1 ? users[0] : undefined;
            }),
            first()
        );
  }

  updateViewsCount(businessOwnerId: string, productId: string, viewsCount: number): Observable<boolean> {

    

    return new Observable();
  }



}
