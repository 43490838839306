<footer class="footer">
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="border-bottom-mute subscribe-block theme-container">
        <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
            <h1 style="color: white">Subscribe our Newsletter</h1>
            <h3 style="color: white">Stay up to date with our latest new and products</h3>
        </div>
        <form [formGroup]="newsletterForm" (ngSubmit)="subscribe()" class="subscribe-search-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
            <input placeholder="Your email address..." formControlName="email" fxFlex>
            <button mat-raised-button color="accent" type="submit" [disabled]="!isSubmitButtonDisplayed">Submit</button>
        </form>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="py-3 border-bottom-mute theme-container">        
     
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="60" ngClass.xs="mt-2" class="contact-information"> 
            <h3 style="color:white;">CONTACT INFORMATION</h3>            
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
                <mat-icon>location_on</mat-icon>
                <span>Kulan.ca<br />322 Vienna Terrace, Ottawa - Canada - K4A-0J4</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>call</mat-icon>
                <span>(613) 424-0353</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>mail_outline</mat-icon>
                <span>admin@kulan.ca</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>schedule</mat-icon>
                <span>Mon - Sun / 9:00AM - 9:00PM</span>
            </p>
          
        </div>
       
    </div>
  
</footer>