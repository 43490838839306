import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { map, switchMap, tap } from 'rxjs/operators';
import { ImageMetadata } from '../../interface/image-metadata';
import { FileService } from './file.service';
import { Logo } from '../../models/logo';

@Injectable({
  providedIn: 'root'
})
export class LogoService {

    private eventAuthError = new BehaviorSubject<string>("");
    eventAuthError$ = this.eventAuthError.asObservable();
    businessOwnerLogos$: Observable<Logo[]>;

    constructor(private db: AngularFirestore, private router: Router,
        private angularFireStorage: AngularFireStorage,
        private fileService: FileService) { }

    public addLogoWithImage(logo: Logo, imageMeta: ImageMetadata, currentUserId: string): Observable<Logo> {
        return this.fileService.uploadImage(currentUserId, imageMeta)
            .pipe(
                switchMap(metadata => {
                    logo.imageFirestoreIds = metadata.id;
                    return this.addLogo(currentUserId, logo);
                })
            )
    }

    public addLogo(currentUserId: string, logo: Logo): Observable<Logo> {
        return from(this.db.collection('users').doc(currentUserId).collection('logos')
            .add({ imageFirestoreIds: logo.imageFirestoreIds, imageStorageUrls: logo.imageStorageUrls }))
            .pipe(
                map(logoRef => {
                    logo.logoId = logoRef.id
                    this.db.collection('users').doc(currentUserId).collection('logos').doc(logo.logoId).set(
                        {
                            logoId: logo.logoId
                        }, { merge: true }
                    )
                    return logo
                })
            )
    }

    getListOfBOLogos(currentUserId: string): Observable<Logo[]> {
        this.businessOwnerLogos$ = this.db.collection<Logo>(`users/${currentUserId}/logos`).snapshotChanges().pipe(
            map(snaps => snaps.map(snap => {
                let data = snap.payload.doc.data() as Logo
                data.imageStorageUrls = data.imageStorageUrls.substring(0, data.imageStorageUrls.indexOf('?'));
                const id = snap.payload.doc.id
                return { id, ...data }
            }))
        );
        return this.businessOwnerLogos$;
    }

    deleteLogo(currentUserId: string, logoId: string): Observable<any> {      
        return from(this.db.collection('users').doc(currentUserId).collection('logos').doc(logoId).delete());
    }
}
