import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Message } from 'src/app/models/message';
import { MessageRepliesItem } from 'src/app/models/messageRepliesItem';
import { User } from 'src/app/models/user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  
  messageRepliesItems$: Observable<MessageRepliesItem[]>;
  messages$: Observable<Message[]>;
  user$: Observable<User>;
  
  constructor(private db: AngularFirestore, private auth: AuthService) { 
    this.user$ = this.auth.user$;
  }

  sendProductMessageReply(messageId: string, messageRepliesItem: MessageRepliesItem): Observable<MessageRepliesItem> {
      return from(this.db.collection<Message>('product-messages').doc(messageId).collection<MessageRepliesItem>('messageRepliesItems').add(messageRepliesItem))
      .pipe(
        map(() => {
          return messageRepliesItem;
        })
      )
  }

  sendOrderMessageReply(messageId: string, messageRepliesItem: MessageRepliesItem): Observable<MessageRepliesItem> {
    return from(this.db.collection<Message>('order-messages').doc(messageId).collection<MessageRepliesItem>('messageRepliesItems').add(messageRepliesItem))
    .pipe(
      map(() => {
        return messageRepliesItem;
      })
    )
  }

  getOrderMessageId(orderId: string): Observable<Message[]> {
    this.messages$ = this.db.collection<Message>('order-messages', ref => ref.where('orderId', '==', orderId))
    .snapshotChanges()
    .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Message
          const id = a.payload.doc.id
          return { id, ...data }
      }))
    ),
    first();
    return this.messages$;
  }

  getAllProductInboundMessages(currentUserid: string): Observable<Message[]> {
      this.messages$ = this.db.collection<Message>('product-messages', ref => ref.where('productOwnerId', '==', currentUserid))
      .snapshotChanges()
      .pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as Message
            const id = a.payload.doc.id
            return { id, ...data }
        }))
      );
      return this.messages$;
  }

  getAllProductOutboundMessages(currentUserid: string): Observable<Message[]> {
    this.messages$ = this.db.collection<Message>('product-messages', ref => ref.where('messageSenderId', '==', currentUserid))
    .snapshotChanges()
    .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Message
          const id = a.payload.doc.id
          return { id, ...data }
      }))
    );
    return this.messages$;
  }

  getMessageRepliesItem(messageId: string): Observable<MessageRepliesItem[]> {
    this.messageRepliesItems$ = this.db.collection<Message>('product-messages').doc(messageId).collection('messageRepliesItems').snapshotChanges()
        .pipe(
            map(snaps => snaps.map(snap => {
                const data = snap.payload.doc.data() as MessageRepliesItem
                const id = snap.payload.doc.id
                return { id, ...data }
            }))
        );
        return this.messageRepliesItems$;
  }

  getMessageOrderRepliesItem(messageId: string): Observable<MessageRepliesItem[]> {
    this.messageRepliesItems$ = this.db.collection<Message>('order-messages').doc(messageId).collection('messageRepliesItems').snapshotChanges()
        .pipe(
            map(snaps => snaps.map(snap => {
                const data = snap.payload.doc.data() as MessageRepliesItem
                const id = snap.payload.doc.id
                return { id, ...data }
            }))
        );
        return this.messageRepliesItems$;
  }
  
  addMessage(message: Message): Observable<Message> {
    return from(this.db.collection<Message>('product-messages').add(message))
      .pipe(
        map((messageRef) => {
          message.messageId = messageRef.id;
          this.db.collection<Message>('product-messages').doc(message.messageId).set({
            messageId: message.messageId,
            productId: message.productId,
            productImageUrl: message.productImageUrl,
            productOwnerId: message.productOwnerId,
            messageSenderId: message.messageSenderId,
            messageDatePlaced: message.messageDatePlaced,
            phone: message.phone
          }),
          { merge: true }
          return message;
        })
      );
  }

  addOrderMessage(message: Message): Observable<Message> {
    return from(this.db.collection<Message>('order-messages').add(message))
      .pipe(
        map((messageRef) => {
          message.messageId = messageRef.id;
          this.db.collection<Message>('order-messages').doc(message.messageId).update({
            messageId: message.messageId,
            orderId: message.orderId,
            productOwnerId: message.productOwnerId,
            messageSenderId: message.messageSenderId,
            messageDatePlaced: message.messageDatePlaced,
          }),
          { merge: true }
          return message;
        })
      );
  }

  addMessageRepliesItem(messageReply: MessageRepliesItem, messageId: string): Observable<boolean> {
    console.log('step 1 = ' + JSON.stringify(messageReply));
    console.log('step 2 = ' + messageId);
    return from(this.db.collection('product-messages').doc(messageId).collection('messageRepliesItems').add(messageReply))
      .pipe(
        map((messageReply) => {
          return (messageReply) ? true : false;
        })
      );
  }

  addMessageOrderRepliesItem(messageReply: MessageRepliesItem, messageId: string): Observable<boolean> {
    return from(this.db.collection('order-messages').doc(messageId).collection('messageRepliesItems').add(messageReply))
      .pipe(
        map((messageReply) => {
          return (messageReply) ? true : false;
        })
      );
  }

  deleteMessage(messageId: string): Observable<boolean> {
    return from (this.db.collection<Message>('product-messages').doc(messageId).delete())
      .pipe(
        map(() => {
          return true;
        })
      );
  }
  

}
