
<mat-sidenav-container>
    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
            <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider"></div>
        <app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>       
    </mat-sidenav>

    <mat-toolbar color="primary">
        <mat-toolbar-row fxLayoutAlign="space-between center" class="top-toolbar theme-container">
            <span fxHide="false" fxHide.gt-sm>
                <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
                    <mat-icon>menu</mat-icon>
                </button>
            </span>
            <span fxShow="false" fxShow.gt-xs fxLayoutAlign="center center"><mat-icon class="mat-icon-sm">call</mat-icon>  {{'pages.top-menu.hotline' | transloco }}: +(613) 424-0353 </span>
            <span fxShow="false" fxShow.gt-sm>{{'pages.top-menu.welcome' | transloco }}</span>
            <app-top-menu></app-top-menu>
        </mat-toolbar-row>
        <mat-toolbar-row fxLayoutAlign="space-between center" class="logo-toolbar theme-container">

            <div fxFlex="20">
                <a class="logo">
                    <a href="/"><img src="assets/logo4.PNG" alt="Kulan" /></a>
                    Kulan
                </a>
            </div>

            <div fxFlex fxShow="false" fxShow.gt-sm>
                <form method="get" class="search-form" fxLayout="row">
                    <button mat-raised-button [matMenuTriggerFor]="categoriesMenu" #categoriesMenuTrigger="matMenuTrigger" type="button" class="mat-elevation-z0 categories text-truncate text-muted">{{category?.name}}<mat-icon>arrow_drop_down</mat-icon></button>
                    <mat-menu #categoriesMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="categories-dropdown">
                        <span (mouseleave)="categoriesMenuTrigger.closeMenu()">
                            <app-category-list [categories]="categories" [categoryParentId]="0" (change)="changeCategory($event)"></app-category-list> 
                        </span>
                    </mat-menu>
                    <input type="text" [formControl]="myControl" placeholder="Type to search..." fxFlex>
                    <button mat-mini-fab (click)="search()" type="button" class="search-btn mat-elevation-z0 text-muted">
                        <mat-icon>search</mat-icon>
                    </button> 
                </form>
            </div>

            <div fxFlex="20" fxLayout="row" fxLayoutAlign="end center" *ngIf="showShoppingCart">
                <div fxLayoutAlign="center center">
                    <button mat-button [matMenuTriggerFor]="cartMenu" #cartMenuTrigger="matMenuTrigger" class="flex-row-button">
                        <mat-icon class="mat-icon-lg">shopping_cart</mat-icon>
                        <span *ngIf="totalCartQuantity > 0" class="cart-items-count">{{ totalCartQuantity }}</span>
                        <div fxLayout="column" fxLayoutAlign="center center" fxShow="false" fxShow.gt-xs class="top-cart">
                            <span>Shopping Cart</span>
                            <span *ngIf="totalCartQuantity > 0">
                                <bdi>
                                    {{totalCartQuantity}} item<span *ngIf="totalCartQuantity > 1">s</span> -
                                    {{currency}}<span class="ml-1">{{totalCartPrice | number : '1.2-2'}}</span>
                                </bdi>
                            </span>
                        </div>
                        <mat-icon class="mat-icon-sm">arrow_drop_down</mat-icon>
                    </button>

                    <mat-menu #cartMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="top-menu-dropdown cart-dropdown">
                        <ng-template matMenuContent>
                            <span (mouseleave)="cartMenuTrigger.closeMenu()" class="d-block">
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                    <b><span>{{totalCartQuantity}} ITEM<span *ngIf="totalCartQuantity > 1">S</span></span></b>
                                    <b><a [routerLink]="['/cart']" class="text-muted">VIEW CART</a></b>
                                </div>
                                <div class="divider mt-1"></div>
                                <div *ngIf="totalCartQuantity === 0" class="py-1 text-muted text-center">You have no items in your shopping cart.</div>

                                <mat-list>
                                    <mat-list-item *ngFor="let shoppingCartItem of shoppingCart">
                                        <h3 matLine class="text-muted"> {{shoppingCartItem.name}} </h3>
                                        <p matLine class="text-muted">
                                            <bdi>{{shoppingCartItem.quantity}} x ${{shoppingCartItem.price | number : '1.2-2'}}</bdi>
                                        </p>
                                        <img [src]="shoppingCartItem.imageUrl" alt="image">
                                        <button mat-icon-button color="warn" class="remove" (click)="remove(shoppingCartItem)" matTooltip="Remove" matTooltipPosition="before">
                                            <mat-icon class="mat-icon-sm">close</mat-icon>
                                        </button>
                                    </mat-list-item>
                                </mat-list>

                                <div *ngIf="shoppingCart.length > 0" class="cart-dropdown-footer">
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted mt-1">
                                        <b>TOTAL:</b>
                                        <b class="new-price">${{totalCartPrice | number : '1.2-2'}}</b>
                                    </div>
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-1">
                                        <button mat-mini-fab color="warn" (click)="clear()" matTooltip="Clear All" matTooltipPosition="after">
                                            <mat-icon>remove_shopping_cart</mat-icon>
                                        </button>
                                        <a [routerLink]="['/cart']" mat-mini-fab color="primary" matTooltip="View Cart" matTooltipPosition="before">
                                            <mat-icon>check</mat-icon>
                                        </a>
                                    </div>
                                </div>

                            </span>
                        </ng-template>
                    </mat-menu>
                </div>
            </div>    

        </mat-toolbar-row>
    </mat-toolbar>

   
    <div class="theme-container main">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition" (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>


</mat-sidenav-container>