import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from '../../utils/app-validators';
import { AuthService } from '../../../shared/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  newsletterForm: FormGroup;
  isSubmitButtonDisplayed: boolean = true;

  constructor(
    public formBuilder: FormBuilder,
    private auth: AuthService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.newsletterForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
    });
  }

  public subscribe(): void {
    let message, status;

    if (this.newsletterForm.valid) {
      const newsletterInfo = {
        email: this.newsletterForm.controls['email'].value,
      };

      this.auth.addNewsletter(newsletterInfo).subscribe(
        () => {
          message = 'Your have being successfully added to Kulan newsletter.';
          status = 'success';
          this.snackBar.open(message, '�', {
            panelClass: [status],
            verticalPosition: 'bottom',
            duration: 5000,
          });
          this.isSubmitButtonDisplayed = false;
        },
        (error) => {
          console.warn(error.responseText);
          console.log({ error });
        }
      );
    }
  }
}
