import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { tap, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private auth: AuthService, private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {

        return this.auth.user$.pipe(
            take(1),
            map(user => user && this.auth.canAdmin(user) ? true : false),
            tap(canAdmin => {
                if (!canAdmin) {
                    this.router.navigate(['account'], { queryParams: { returnUrl: state.url } });
                }
            })
        );

    }
}
