<div fxLayout="row" fxLayout.xs="column" [fxLayoutAlign]="align" class="text-muted">
    <div *ngIf="product?.quantity > 0 && (type=='all' || type=='wish')">
        <bdi>
            <span *ngIf="type!='wish'" class="fw-500">Quantity:</span> 
            <button mat-icon-button matTooltip="Remove" (click)="decrement()"><mat-icon>remove</mat-icon></button>
            <span> {{count}} </span>
            <button mat-icon-button matTooltip="Add" (click)="increment()"><mat-icon>add</mat-icon></button>
        </bdi>
    </div>
    <div *ngIf="type!='wish'">
        <button  color="primary" *ngIf="type!='all'" mat-icon-button matTooltip="Quick view" (click)="openProductDialog(product)"><mat-icon>remove_red_eye</mat-icon></button>
    </div>
</div> 
<div *ngIf="product?.quantity == 0 && type=='all'" class="bg-warn p-1 mt-2">
    Sorry, this item is unavailable. Please choose a different one.
</div>