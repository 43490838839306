import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessengerService {

    subject = new Subject()
   
    constructor() { }

    sendMessage(isShoppingCartItemDeleted: boolean) {
        this.subject.next(isShoppingCartItemDeleted); // Triggering an event
    }

    getMessage() {
        return this.subject.asObservable();
    }

    sendBusinessNameSearchCriteria(businessName: string) {
        this.subject.next(businessName);
    }

    getBusinessNameSearchCriteria(): Observable<any> {
        return this.subject.asObservable();
    }

    sendSearchButtonDisplayed(isSearchDisplayed: boolean) {
        this.subject.next(isSearchDisplayed);
    }

    getSearchButtonDisplayed(): Observable<any> {
        return this.subject.asObservable();
    }

    sendShoppingCartButtonDisplayed(isSearchDisplayed: boolean) {
        this.subject.next(isSearchDisplayed);
    }

    getShoppingCartDisplayed(): Observable<any> {
        return this.subject.asObservable();
    }
}
