<div>
  <div class="main-slider">
    <div class="swiper-container h-100" [swiper]="config">
      <div class="swiper-wrapper h-100">      
        <div *ngFor="let slide of slides" class="swiper-slide">
          <div [attr.data-background]="slide" class="slide-item swiper-lazy">
              <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination white"></div>
      <button mat-mini-fab color="primary" class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
      <button mat-mini-fab color="primary" class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
  </div>
</div>


