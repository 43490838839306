import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, of, from, defer, pipe } from 'rxjs';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { map, first } from 'rxjs/operators';
import { User } from '../../models/user';
import { Product } from '../../models/product';
import { convertSnaps } from './db-util';
import { HttpClient } from '@angular/common/http';
import { Category } from '../../interface/category';


@Injectable({
  providedIn: 'root'
})
export class BusinessOwnersService {
   
    user$: Observable<User>;
    isEmailVerified$: Observable<boolean>;
    private eventAuthError = new BehaviorSubject<string>("");
    eventAuthError$ = this.eventAuthError.asObservable();
    businessOwners$: Observable<User[]>;
    businessOwnerProducts$: Observable<Product[]>;
    public url = "assets/data/";

    constructor(private afAuth: AngularFireAuth,
        private db: AngularFirestore,
        public http: HttpClient) {

        // Get auth data, then get firestore user document || null
        this.user$ = afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    return this.db.doc<User>(`users/${user.uid}`).valueChanges();
                }
                else {
                    return of(null);
                }
            })
        );
    }
    
    /*
    loadAllBusinessOwners(): Observable<User[]> {
        return this.db.collection<User>('users', ref => ref.where('isBusinessOwner', '==', true)
            .where('isLogoCreated', '==', true)
            .where('isTaxApplied', '==', true)
            .where('isPublished', '==', true)).snapshotChanges()
            .pipe(
                map(snaps => {
                    const users = convertSnaps<User>(snaps)
                    users.forEach(user => {
                        const indexOfFirst = user.uploadedLogoImageUrl.indexOf('?');
                        user.uploadedLogoImageUrl = user.uploadedLogoImageUrl.substring(0, indexOfFirst);
                    })
                    return users;
                })
            );
    }

    
    loadAllBusinessOwnersByCity(city: string): Observable<User[]> {
        return this.db.collection<User>('users', ref => ref.where('isBusinessOwner', '==', true)
            .where('isLogoCreated', '==', true)
            .where('isPublished', '==', true)
            .where('address.city', '==', city.toLowerCase())).snapshotChanges()
            .pipe(
                map(snaps => {
                    const users = convertSnaps<User>(snaps);
                    users.forEach(user => {
                        const indexOfFirst = user.uploadedLogoImageUrl.indexOf('?');
                        user.uploadedLogoImageUrl = user.uploadedLogoImageUrl.substring(0, indexOfFirst);
                    })
                    return users;
                })
            );
    }

    loadAllBusinessOwnersByLineOfBusiness(lineOfBusiness: string): Observable<User[]> {
        return this.db.collection<User>('users', ref => ref.where('isBusinessOwner', '==', true)
            .where('isLogoCreated', '==', true)
            .where('isPublished', '==', true)
            .where('lineOfBusiness', '==', lineOfBusiness.toLowerCase())).snapshotChanges()
            .pipe(
                map(snaps => {
                    const users = convertSnaps<User>(snaps);
                    users.forEach(user => {
                        const indexOfFirst = user.uploadedLogoImageUrl.indexOf('?');
                        user.uploadedLogoImageUrl = user.uploadedLogoImageUrl.substring(0, indexOfFirst);
                    })
                    return users;
                })
            );
    }

    loadAllBusinessOwnersByBusinessName(businessName: string): Observable<User[]> {
        return this.db.collection<User>('users', ref => ref.where('isBusinessOwner', '==', true)
            .where('isLogoCreated', '==', true)
            .where('isPublished', '==', true)
            .where('businessName', '==', businessName)).snapshotChanges()
            .pipe(
                map(snaps => {
                    const users = convertSnaps<User>(snaps);
                    users.forEach(user => {
                        const indexOfFirst = user.uploadedLogoImageUrl.indexOf('?');
                        user.uploadedLogoImageUrl = user.uploadedLogoImageUrl.substring(0, indexOfFirst);
                    })
                    return users;
                })
            );
    }
    */

    loadAllProductBusinessOwnersByCity(city: string): Observable<User[]> {
        return this.db.collection<User>('users', ref => ref.where('isBusinessOwner', '==', true)
            .where('roles.productOrientedBusiness', '==', true)
           // .where('isPublished', '==', true)
            .where('address.city', '==', city)).snapshotChanges()
            .pipe(
                map(snaps => {
                    const users = convertSnaps<User>(snaps);
                    return users;
                })
            );
    }

    loadAllServiceBusinessOwnersByCity(city: string): Observable<User[]> {
        return this.db.collection<User>('users', ref => ref.where('isBusinessOwner', '==', true)
           .where('roles.serviceOrientedBusiness', '==', true)
           // .where('isPublished', '==', true)
            .where('address.city', '==', city)).snapshotChanges()
            .pipe(
                map(snaps => {
                    const users = convertSnaps<User>(snaps);
                    return users;
                })
            );
    }

    getBusinessOwnerProducts(currentUserId: string): Observable<Product[]> {
        this.businessOwnerProducts$ = this.db.collection<User>('users', ref => ref.where('isBusinessOwner', '==', true)).doc(currentUserId).collection('products').snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const data = a.payload.doc.data() as Product
                const id = a.payload.doc.id
                return { id, ...data }
            }))
            
        );
        return this.businessOwnerProducts$;
    }

    getBusinessOwner(currentUserId: string): Observable<User> {
        this.user$ = this.db.collection<User>('users').doc(currentUserId).snapshotChanges()
            .pipe(
                map(snap => {
                    const data = snap.payload.data() as User;
                    return data;
                })
        );
        return this.user$;
    }

    findBusinessOwnerByUrl(boUrlTransition: string): Observable<User> {
        return this.db.collection<User>('users', ref => ref.where('urlTransition', '==', boUrlTransition)).snapshotChanges()
            .pipe(
                map(snaps => {
                    const users = convertSnaps<User>(snaps);
                    return users.length == 1 ? users[0] : undefined;
                }),
                first()
            );
    }

    getCategories() {
        return this.http.get<Category[]>(this.url + 'categories.json');
    }

    
}
