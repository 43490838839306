// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCJrcpWqtJNgMkp8s4g80H0IQhoEsAQggs",
    authDomain: "kulan-f0f46.firebaseapp.com",
    projectId: "kulan-f0f46",
    storageBucket: "kulan-f0f46.appspot.com",
    messagingSenderId: "289238063081",
    appId: "1:289238063081:web:ab7a570da3fd37b2225d78",
    measurementId: "G-WQZ5VFNL4V"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
