import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EventService } from 'src/app/shared/services/event.service';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit {

    siteLanguage = 'English';
    isEmailVerified$: Observable<boolean>;

   languageList = [
    { code: 'en', label: 'English' },
    { code: 'fr', label: 'Français' },
    { code: 'so', label: 'Somali' }
   ];

    
    constructor(
        public auth: AuthService, private service: TranslocoService, private eventService: EventService) { }

    ngOnInit() {
        this.isEmailVerified$ = this.auth.user$
        .pipe(
            map(user => user ? user.emailVerified : null)
    );
    }

    logout() {
        this.auth.logout();
    }

    changeSiteLanguage(language: string): void {
        this.service.setActiveLang(language);
        this.siteLanguage = this.languageList.find(f => f.code === language).label;
    }

}
