import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { LoadingService } from './shared/loading/loading.service';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [LoadingService]
})

export class AppComponent {
  loading: boolean = false;
  public settings: Settings;

    constructor(public appSettings: AppSettings,
        public auth: AuthService,
        public router: Router) {
    this.settings = this.appSettings.settings;
  }

    ngOnInit() {
   // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh  
      
    }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          window.scrollTo(0,0);
      }
    })  
    }
}
