import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from 'src/app/interface/category';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  public url = "assets/data/";
  private user$: Observable<User>;
  constructor(public http: HttpClient, 
              private db: AngularFirestore) { }

  individualBanners: string[] = ['assets/images/others/amal.PNG', 'assets/images/others/somtel.PNG', 'assets/images/others/odpic.PNG'];

  getCategories() {
    return this.http.get<Category[]>(this.url + 'categories.json');
  }

  public getBanners(): Observable<any[]>{
    return this.http.get<Category[]>(this.url + 'banners.json');
  }

  public getIndividualBanners(): Observable<string[]>{
    return of(this.individualBanners);
  }

  public getUser(currentUserId: string): Observable<User> {
    this.user$ = this.db.collection<User>('users').doc(currentUserId).snapshotChanges()
    .pipe(
        map(snap => {
            const data = snap.payload.data() as User;
            return data;
        })
    );
    return this.user$;
  }

  public getUserV2(currentUserId: any): Observable<User> {
    this.user$ = this.db.collection<User>('users').doc(currentUserId).snapshotChanges()
    .pipe(
        map(snap => {
            const data = snap.payload.data() as User;
            return data;
        })
    );
    return this.user$;
  }

}
