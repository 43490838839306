import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  public _subject =  new ReplaySubject<any>();
  
  emit<T>(data: T) {
    this._subject.next(data);
  }

  on<T>(): Observable<T> {
    return this._subject.asObservable();
  }
}
